import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { RecommendationViewModel } from 'src/app/shared/models/autogenerated';
import { FileService } from 'src/app/shared/services/file.service';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'recommendation-sidebar',
  templateUrl: './recommendation-sidebar.component.html',
  styleUrls: ['./recommendation-sidebar.component.scss'],
})
export class RecommendationSidebarComponent implements OnInit, OnDestroy, OnChanges {
  constructor(private pfdService: FileService, private activatedRoute: ActivatedRoute) {}
  @Input()
  public recommendation: RecommendationViewModel;
  public hasTechnologies: boolean;
  public hasPFDs: boolean;
  public faExternalLinkAlt = faExternalLinkAlt;
  private readonly subscription = new Subscription();
  private systemId$: Observable<string>;

  public activeTab$ = this.activatedRoute.queryParamMap.pipe(
    map(params => {
      const tabFromParams = params.get('recommendationSidebar');
      const defaultTab = this.tabs[this.tabs.length - 1]?.name ?? 'pfd';
      const selectedTab = isRecommendationSidebarType(tabFromParams) ? tabFromParams : defaultTab;
      return this.tabs.find(tab => tab.name === selectedTab) !== null ? selectedTab : defaultTab;
    })
  );

  public tabs: RecommendationSidebarTabType[];

  ngOnInit(): void {
    this.systemId$ = this.activatedRoute.paramMap.pipe(
      map(params => params.get('systemId')),
      filter(systemId => systemId != null)
    );
  }

  ngOnChanges(change: SimpleChanges): void {
    if (change.recommendation && this.recommendation) {
      this.hasTechnologies = this.recommendation.technologies?.length > 0;
      this.hasPFDs = this.recommendation.pfds?.length > 0;
      const tabs = [];
      if (this.hasTechnologies) {
        tabs.push({ name: 'technology', label: 'Technology' });
      }
      if (this.hasPFDs) {
        tabs.push({ name: 'pfd', label: 'PFDs' });
      }
      this.tabs = tabs;
    }
  }
  downloadPfd(code: string) {
    this.subscription.add(this.pfdService.downloadPfd(code).subscribe());
  }

  openPfd(code: string) {
    this.subscription.add(this.pfdService.openPfd(code).subscribe());
  }

  downloadAllPfds() {
    this.subscription.add(
      this.systemId$
        .pipe(
          take(1),
          switchMap(systemId => this.pfdService.downloadAllPfds(systemId))
        )
        .subscribe()
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

const TypeList = ['pfd', 'technology'] as const;
type RecommendationSidebarType = typeof TypeList[number];
type RecommendationSidebarTabType = { name: RecommendationSidebarType; label: string };

function isRecommendationSidebarType(str: string): str is RecommendationSidebarType {
  return !!TypeList.find(typeName => str === typeName);
}
