import { Pipe, PipeTransform } from '@angular/core';
import { Params } from '@angular/router';
import { ProjectViewModel } from 'src/app/shared/models/autogenerated';
import { FilterModel } from 'src/app/shared/models/filter.model';
import { Project } from '../models/project.model';

@Pipe({
  name: 'DistinctProjectValues',
})
export class DistinctProjectValuesPipe implements PipeTransform {
  transform(value: Project[], filterModel: FilterModel, filters: Params): { [id: string]: string } {
    return value.reduce<{ [id: string]: string }>(
      (distinctValues, currentProject) =>
        currentProject[filterModel.displayPropertyName] &&
        !Object.keys(distinctValues).includes(currentProject[filterModel.displayPropertyName]) &&
        ShouldIncludeCurrentValue(currentProject, filterModel.filterProperty, filters)
          ? { ...distinctValues, [currentProject[filterModel.displayPropertyName]]: currentProject[filterModel.filterProperty] }
          : distinctValues,
      { '': null }
    );
  }
}

// Need to filter out values displayed in filters in order in which they were selected
function ShouldIncludeCurrentValue(currentProject: ProjectViewModel, propertyName: string, filters: Params): boolean {
  const prefixCompare = (s1: string, s2: string) => s1.toLowerCase().startsWith(s2.toLowerCase());
  if (!filters) {
    return true;
  }
  for (const key of Object.keys(filters)) {
    if (key === propertyName) {
      break;
    }
    if (key !== 'status' && (key === 'name' ? !prefixCompare(currentProject.name, filters[key]) : currentProject[key] !== filters[key])) {
      return false;
    }
  }
  return true;
}
