import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../components/snackbar/snackbar.component';
import { SnackbarData } from '../models/snackbar-data.model';

@Injectable({
  providedIn: 'root',
})
export class ToastMessagesService {
  snackBarDefaultConfig: MatSnackBarConfig = {
    horizontalPosition: 'end',
    verticalPosition: 'top',
  };
  constructor(private snackBar: MatSnackBar, private zone: NgZone) {}

  displayError(msg: string) {
    this.displayMessage(msg, 'error-snackbar', 'error');
  }

  displaySuccess(msg: string) {
    this.displayMessage(msg, 'success-snackbar', 'check_circle_outline', 3000);
  }

  private displayMessage(message: string, panelClass: string, icon?: string, duration?: number) {
    const config: MatSnackBarConfig = {
      ...this.snackBarDefaultConfig,
      duration,
      panelClass: [panelClass],
      data: { icon, message, dismissHandler: () => this.snackBar.dismiss() } as SnackbarData,
    };

    this.openSnackbar(config);
  }

  private openSnackbar(config: MatSnackBarConfig) {
    // Open only one snackbar if multiple errors occur
    if (!this.snackBar._openedSnackBarRef) {
      if (NgZone.isInAngularZone()) {
        this.snackBar.openFromComponent(SnackbarComponent, config);
      } else {
        // Need it to prevent from appearing in wrong place
        this.zone.run(() => this.snackBar.openFromComponent(SnackbarComponent, config));
      }
    }
  }
}
