// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  border: 3px solid #da9c36;
  color: #000;
  padding: 15px 20px;
  align-items: center;
  text-align: justify;
  flex-direction: row;
  gap: 40px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
