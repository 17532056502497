import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SystemViewModel } from 'src/app/shared/models/autogenerated';

@Component({
  selector: 'app-system-list',
  templateUrl: './system-list.component.html',
  styleUrls: ['./system-list.component.scss'],
})
export class SystemListComponent {
  @Input()
  public title: string;

  @Input()
  public systems: SystemViewModel[];

  @Input()
  public isSelected: boolean;

  @Output()
  selectSystem = new EventEmitter<SystemViewModel>();

  @Output()
  removeSystem = new EventEmitter<SystemViewModel>();

  @Output()
  selectAllSystems = new EventEmitter<SystemViewModel[]>();

  @Output()
  removeAllSystems = new EventEmitter<SystemViewModel[]>();
}
