import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../core/core.module';
import { RouterModule, Routes } from '@angular/router';
import { ProjectComponent } from './pages/project/project.component';
import { SystemSelectionComponent } from '../facility/pages/system-selection/system-selection.component';
import { ProjectFacilitiesComponent } from './components/project-facilities/project-facilities.component';
import { ProjectMembersListComponent } from './components/project-members-list/project-members-list.component';

const routes: Routes = [
  {
    path: ':projectId',
    component: ProjectComponent,
    children: [
      {
        path: '',
        component: ProjectFacilitiesComponent,
        children: [{ path: '', loadChildren: () => import('src/app/facility/facility.module').then(m => m.FacilityModule) }],
      },
      {
        path: 'members',
        component: ProjectMembersListComponent,
      },
    ],
  },
  {
    path: ':projectId/facility/:facilityId/system-selection',
    component: SystemSelectionComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), CommonModule, CoreModule],
  exports: [RouterModule],
})
export class ProjectRoutingModule {}
