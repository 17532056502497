import { Component, Input } from '@angular/core';
import { FacilityViewModel } from 'src/app/shared/models/autogenerated';

@Component({
  selector: 'app-facility-overview',
  templateUrl: './facility-overview.component.html',
  styleUrls: ['./facility-overview.component.scss'],
})
export class FacilityOverviewComponent {
  @Input()
  public facility: FacilityViewModel;
}
