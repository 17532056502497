import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewProjectComponent } from './pages/new-project/new-project.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NewProjectRoutingModule } from './new-project-routing.module';
import { MatDialogModule } from '@angular/material/dialog';
import { NewProjectOverviewComponent } from './pages/new-project-overview/new-project-overview.component';
import { NewProjectFacilitiesComponent } from './pages/new-project-facilities/new-project-facilities.component';
import { NewProjectDialogEntryComponent } from './components/new-project-dialog-entry/new-project-dialog-entry.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from './../shared/shared.module';
import { FacilityModule } from '../facility/facility.module';
import { AddEditProjectFacilityComponent } from './pages/add-edit-project-facility/add-edit-project-facility.component';
import { ProjectModule } from '../project/project.module';
import { NewProjectSidebarComponent } from './components/new-project-sidebar/new-project-sidebar.component';
import { NewProjectTeamMembersComponent } from './pages/new-project-team-members/new-project-team-members.component';
import { ProjectCreatedConfirmationComponent } from './pages/project-created-confirmation/project-created-confirmation.component';

@NgModule({
  declarations: [
    NewProjectComponent,
    NewProjectOverviewComponent,
    NewProjectSidebarComponent,
    AddEditProjectFacilityComponent,
    NewProjectFacilitiesComponent,
    NewProjectDialogEntryComponent,
    NewProjectTeamMembersComponent,
    ProjectCreatedConfirmationComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    NewProjectRoutingModule,
    MatDialogModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    SharedModule,
    FacilityModule,
    ProjectModule,
  ],
  exports: [NewProjectComponent, NewProjectDialogEntryComponent],
})
export class NewProjectFlowModule {}
