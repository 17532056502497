import { Component, OnInit, OnDestroy, Input, Output } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { User } from '@microsoft/microsoft-graph-types';
import { EventEmitter } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { GraphService } from 'src/app/core/services/graph-service';
import { UserService } from 'src/app/core/services/user.service';
import { IConfigAPIClient, ParameterCommentViewModel } from 'src/app/shared/models/autogenerated';
import { DeleteConfirmationDialogComponent } from 'src/app/shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'question-comment',
  templateUrl: './question-comment.component.html',
  styleUrls: ['./question-comment.component.scss'],
})
export class QuestionCommentComponent implements OnInit, OnDestroy {
  @Input()
  public comment: ParameterCommentViewModel;

  @Output()
  public refresh = new EventEmitter<void>();

  userProfile$: Observable<User>;
  userImage$: Observable<SafeUrl>;
  photoBlob$: Observable<Blob>;
  readonly currentUserProfile$ = this.userService.currentUserProfile$;

  private readonly subscription = new Subscription();

  editModeActive: boolean = false;
  editDeleteAllowed: boolean = false;

  constructor(
    public graphService: GraphService,
    private sanitizer: DomSanitizer,
    private userService: UserService,
    private iconfigAPIClient: IConfigAPIClient,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.userProfile$ = this.graphService.getUserByOid(this.comment.createdBy);
    this.photoBlob$ = this.graphService.getUserPhotoByOid(this.comment.createdBy);
    this.userImage$ = this.photoBlob$.pipe(
      filter(photo => photo != null),
      map(photo => URL.createObjectURL(photo)),
      map(objectURL => this.sanitizer.bypassSecurityTrustUrl(objectURL))
    );
    this.currentUserProfile$
      .pipe(
        tap(profile => {
          this.editDeleteAllowed = profile.idTokenClaims['oid'] === this.comment.createdBy;
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  toggleEdit(): void {
    this.editModeActive = !this.editModeActive;
  }

  editComment(toEdit: string): void {
    let editedComment = {
      id: this.comment.id,
      parameterId: this.comment.parameterId,
      content: toEdit,
    } as ParameterCommentViewModel;

    this.subscription.add(this.iconfigAPIClient.updateParameterComment(this.comment.id, editedComment).subscribe(_ => this.refresh.emit()));
    this.editModeActive = false;
  }

  deleteComment(): void {
    this.subscription.add(
      this.dialog
        .open(DeleteConfirmationDialogComponent, {
          panelClass: 'delete-dialog',
          disableClose: true,
          data: { itemToDelete: 'this comment' },
        })
        .afterClosed()
        .pipe(
          filter(result => result),
          switchMap(_ => this.iconfigAPIClient.deleteParameterComment(this.comment.id)),
          tap(_ => this.refresh.emit())
        )
        .subscribe()
    );
  }
}
