import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SystemViewModel } from 'src/app/shared/models/autogenerated';

@Component({
  selector: 'app-system-card',
  templateUrl: './system-card.component.html',
  styleUrls: ['./system-card.component.scss'],
})
export class SystemCardComponent {
  @Input()
  public system: SystemViewModel;
  @Input()
  public isSelected: boolean;

  @Output()
  selectSystem = new EventEmitter<SystemViewModel>();

  @Output()
  removeSystem = new EventEmitter<SystemViewModel>();
}
