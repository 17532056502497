// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: white;
}

.snackbar-icon {
  margin-right: 10px;
}

.close-btn {
  margin-left: auto;
  color: #fff;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
