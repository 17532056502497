import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, of, Subscription } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { FacilityViewModel, IConfigAPIClient } from 'src/app/shared/models/autogenerated';
import { FacilityService } from '../../../facility/services/facility.service';
import { INewProjectDialogComponent } from '../../models/new-project-dialog-component.model';
import { NEW_PROJECT_DIALOG_OUTLET } from '../../new-project-flow.constants';

@Component({
  selector: 'app-add-edit-project-facility',
  templateUrl: './add-edit-project-facility.component.html',
  styleUrls: ['./add-edit-project-facility.component.scss'],
})
export class AddEditProjectFacilityComponent implements OnDestroy, INewProjectDialogComponent {
  constructor(
    private activatedRoute: ActivatedRoute,
    private facilityService: FacilityService,
    private iconfigAPIClient: IConfigAPIClient,
    private router: Router
  ) {}
  private readonly subscription = new Subscription();

  projectId$ = this.activatedRoute.paramMap.pipe(map(params => params.get('newProjectId')));
  facilityId$ = this.activatedRoute.paramMap.pipe(map(params => params.get('newFacilityId')));

  title$ = this.facilityId$.pipe(map(facilityId => (this.createNew(facilityId) ? 'Add facility' : 'Edit facility')));
  facility$ = combineLatest([this.facilityId$, this.projectId$]).pipe(
    switchMap(([facilityId, projectId]) => {
      if (facilityId == null) {
        return of({ projectId } as FacilityViewModel);
      } else {
        return this.facilityService.getFacility(facilityId);
      }
    })
  );
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public createNew(facilityId): boolean {
    return facilityId == null;
  }

  Submit(facility: FacilityViewModel) {
    this.subscription.add(
      this.facilityId$
        .pipe(
          take(1),
          switchMap(facilityId => {
            return this.createNew(facilityId)
              ? this.iconfigAPIClient.createFacility(facility)
              : this.iconfigAPIClient.updateFacility(facility.id, facility);
          })
        )
        .subscribe(() => this.submitCallback())
    );
  }
  submitCallback() {
    this.facilityService.refreshFacility();
    this.navigateToFacilitiesList();
  }
  public navigateToFacilitiesList() {
    this.subscription.add(
      this.projectId$
        .pipe(take(1))
        .subscribe(projectId => this.router.navigate([{ outlets: { [NEW_PROJECT_DIALOG_OUTLET]: [projectId, 'facilities'] } }]))
    );
  }

  goBack = () => this.navigateToFacilitiesList();
}
