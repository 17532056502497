import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { IConfigAPIClient, ProjectSearchViewModel, ProjectViewModel } from '../models/autogenerated';
import { UserService } from 'src/app/core/services/user.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  constructor(private iConfigAPIClient: IConfigAPIClient, private userService: UserService) {}

  public projectRefreshSubject = new BehaviorSubject<void>(void 0);
  public projectRefresh$ = this.projectRefreshSubject.asObservable();
  public allProjects$ = this.projectRefresh$.pipe(
    switchMap(_ => this.iConfigAPIClient.getAllProjects()),
    shareReplay(1)
  );

  public myProjects$ = this.projectRefresh$.pipe(
    switchMap(_ => this.getMyProjects()),
    shareReplay(1)
  );

  public getProject(projectId: string): Observable<ProjectViewModel> {
    return this.projectRefresh$.pipe(
      switchMap(_ => this.iConfigAPIClient.getProjectById(projectId)),
      shareReplay(1)
    );
  }

  public refreshProjectList(): void {
    this.projectRefreshSubject.next();
  }

  public getMyProjects(): Observable<ProjectSearchViewModel[]> {
    return this.projectRefresh$.pipe(
      switchMap(() => this.userService.currentUserProfile$),
      map(profile => (profile.idTokenClaims as any).oid),
      switchMap(oid => this.iConfigAPIClient.getProjects(oid, '', '', '', '', ''))
    );
  }
}
