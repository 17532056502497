import { Component, Input } from '@angular/core';
import { SystemViewModel } from 'src/app/shared/models/autogenerated';

@Component({
  selector: 'app-system-tab-strip',
  templateUrl: './system-tab-strip.component.html',
  styleUrls: ['./system-tab-strip.component.scss'],
})
export class SystemTabStripComponent {
  @Input()
  public activeSystemId: number;

  @Input()
  public systems: SystemViewModel[];

  @Input()
  childPath: string;
}
