// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
  It applies to page with side bar.
  Example: systemParams -> systemParams.component.scss
*/
/*
  It applies to the title (header) of page content body.
*/
/*
  It describes common behavior on mouse hover.
*/
/*
  It describes light shadow for any page block.
*/
/*
  It applies to entity block like project, facility which has own title, edit button and body.
*/
/*
  It applies together with entity-card mixin, which has been described above
*/
/*
  It applies together with 'entity-card' mixin, which has been described above.
*/
/*
  It applies together with 'entity-card' mixin, which has been described above.
*/
:host {
  display: flex;
  flex: 1;
}

.navigation-item-container {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.steps {
  flex: 1 0 0;
  border: 1px solid #cccccc;
  padding: 27px 24px 27px 24px;
  min-width: 120px;
  overflow: auto;
}

.navigation-item:not(:last-child) .vertical-line {
  border-left: 3px dashed #cccccc;
  height: 30px;
  margin-left: 10px;
}

.navigation-item .navigation-item-container span {
  font-weight: normal;
  font-size: 16px;
  font-family: GothamBold, sans-serif;
  color: #000000;
}
.navigation-item .navigation-item-container mat-icon {
  width: 20px;
  margin-right: 10px;
  color: #0066b2;
}
.navigation-item .navigation-item-container mat-icon:after {
  content: "check_circle_outline";
}
.navigation-item.active .navigation-item-container span {
  color: #0066b2;
}
.navigation-item.active .navigation-item-container mat-icon {
  color: #0056b5;
}
.navigation-item.active .navigation-item-container mat-icon:after {
  content: "radio_button_checked";
}

.active ~ .navigation-item .navigation-item-container span {
  color: #919191;
}
.active ~ .navigation-item .navigation-item-container mat-icon {
  color: #cccccc;
}
.active ~ .navigation-item .navigation-item-container mat-icon:after {
  content: "radio_button_unchecked";
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
