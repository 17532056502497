import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ProjectService } from 'src/app/shared/services/project.service';
import { GraphService } from '../../services/graph-service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  constructor(
    private userService: UserService,
    private sanitizer: DomSanitizer,
    private graphService: GraphService,
    private projectService: ProjectService
  ) {}

  userPhotoUrl$: Observable<SafeUrl>;
  readonly PhotoBlob$ = this.userService.currentUserPhoto$;
  readonly userInfo$ = this.graphService.getProfile();
  readonly myProjects$ = this.projectService.myProjects$;

  ngOnInit(): void {
    this.userPhotoUrl$ = this.PhotoBlob$.pipe(
      filter(photo => photo != null),
      map(photo => URL.createObjectURL(photo)),
      map(objectURL => this.sanitizer.bypassSecurityTrustUrl(objectURL))
    );
  }
}
