import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { FacilityService } from 'src/app/facility/services/facility.service';
import { ProjectSummary } from 'src/app/project/models/projectSummary.model';
import { FacilityViewModel, ProjectViewModel } from 'src/app/shared/models/autogenerated';

@Component({
  selector: 'app-project-card',
  templateUrl: './project-card.component.html',
  styleUrls: ['./project-card.component.scss'],
})
export class ProjectCardComponent implements OnInit {
  @Input()
  public project: ProjectViewModel;
  projectInfo$: Observable<ProjectSummary>;

  constructor(private facilityService: FacilityService) {}

  ngOnInit(): void {
    this.projectInfo$ = this.facilityService
      .getFacilitiesInProject(this.project.id)
      .pipe(map(fac => <ProjectSummary>{ facilitiesNo: fac.length, systemsNo: this.countSystems(fac) })); //todo: add team members counting
  }

  countSystems(facilities: FacilityViewModel[]): Number {
    return facilities.reduce(function (sum, facility) {
      return sum + facility.numSystems;
    }, 0);
  }
}
