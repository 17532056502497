import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
} from '@azure/msal-angular';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory } from './msal.setup';
import { QuestionnaireModule } from './questionnaire/questionnaire.module';
import { FormsModule } from '@angular/forms';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { RecommendationModule } from './recommendation/recommendation.module';
import { FacilityModule } from './facility/facility.module';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProjectModule } from './project/project.module';
import { SystemModule } from './system/system.module';
import { NewProjectFlowModule } from './new-project-flow/new-project-flow.module';
import { ApiErrorHandler } from './shared/handlers/api-error.handler';
import { EditNotificationsInterceptor } from './shared/interceptor/edit-notification.interceptor';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

const appComponents = [AppComponent];

@NgModule({ declarations: [appComponents],
    exports: [appComponents],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        FormsModule,
        MsalModule,
        SharedModule,
        QuestionnaireModule,
        CoreModule,
        RecommendationModule,
        FacilityModule,
        MatTableModule,
        MatSortModule,
        BrowserAnimationsModule,
        ProjectModule,
        SystemModule,
        NewProjectFlowModule,
        FontAwesomeModule
        ], providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: EditNotificationsInterceptor,
            multi: true,
        },
        {
            provide: ErrorHandler,
            useClass: ApiErrorHandler,
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory,
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory,
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory,
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {}
