import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { EDIT_NOTIFICATION_ENDPOINTS } from '../constants';
import { API_BASE_URL } from '../models/autogenerated';
import { ToastMessagesService } from '../services/toast-messages.service';

@Injectable()
export class EditNotificationsInterceptor implements HttpInterceptor {
  constructor(@Inject(API_BASE_URL) private baseUrl: string, private toastService: ToastMessagesService) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(event => {
        if (
          event instanceof HttpResponse &&
          event.url.startsWith(this.baseUrl) &&
          event.status === 202 &&
          // Check if response comes from endpoint, which require toast notification
          EDIT_NOTIFICATION_ENDPOINTS.some(e => new RegExp(e, 'i').test(event.url))
        ) {
          this.toastService.displaySuccess('Edit changes were saved');
        }
      })
    );
  }
}
