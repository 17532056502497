import { Component, Input } from '@angular/core';
import { FacilityViewModel } from 'src/app/shared/models/autogenerated';

@Component({
  selector: 'app-facility-list',
  templateUrl: './facility-list.component.html',
  styleUrls: ['./facility-list.component.scss'],
})
export class FacilityListComponent {
  @Input()
  public title: string;

  @Input()
  public facilities: FacilityViewModel[];
}
