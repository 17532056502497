import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-user-button',
  templateUrl: './user-button.component.html',
  styleUrls: ['./user-button.component.scss'],
})
export class UserButtonComponent implements OnInit, OnDestroy {
  userImage$: Observable<SafeUrl>;
  readonly currentUserProfile$ = this.userService.currentUserProfile$;
  readonly currentUserPhoto$ = this.userService.currentUserPhoto$;
  private readonly subscription = new Subscription();
  isSidebarOn = false;

  constructor(
    private userService: UserService,
    private sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.userImage$ = this.currentUserPhoto$.pipe(
      filter(photo => photo != null),
      map(photo => URL.createObjectURL(photo)),
      map(objectURL => this.sanitizer.bypassSecurityTrustUrl(objectURL))
    );

    this.subscription.add(
      this.activatedRoute.queryParamMap.pipe(map(params => params.has('sidebar'))).subscribe(par => (this.isSidebarOn = !par))
    );
  }

  toggleSidebar() {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: this.isSidebarOn ? { sidebar: 'show' } : { sidebar: null },
      queryParamsHandling: 'merge',
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
