import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { INewProjectDialogComponent } from '../../models/new-project-dialog-component.model';
import { NEW_PROJECT_DIALOG_OUTLET } from '../../new-project-flow.constants';

@Component({
  selector: 'app-new-project-team-members',
  templateUrl: './new-project-team-members.component.html',
  styleUrls: ['./new-project-team-members.component.scss'],
})
export class NewProjectTeamMembersComponent implements OnDestroy, INewProjectDialogComponent {
  constructor(private activatedRoute: ActivatedRoute, private router: Router) {}
  private readonly subscription = new Subscription();

  title$: Observable<string> = of('Add project members');
  projectId$ = this.activatedRoute.paramMap.pipe(map(params => params.get('newProjectId')));
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public navigateToOverview() {
    this.subscription.add(
      this.projectId$
        .pipe(take(1))
        .subscribe(projectId => this.router.navigate([{ outlets: { [NEW_PROJECT_DIALOG_OUTLET]: [projectId, 'overview'] } }]))
    );
  }

  goBack = () => this.navigateToOverview();

  goToFacilities() {
    this.projectId$
      .pipe(take(1))
      .subscribe(projectId => this.router.navigate([{ outlets: { [NEW_PROJECT_DIALOG_OUTLET]: [projectId, 'facilities'] } }]));
  }
}
