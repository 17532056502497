import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ParameterValueFormat',
})
export class ParameterValueFormat implements PipeTransform {
  transform<T>(value: T, args?: any): string {
    // boolean parameters are still passed on as strings
    if (typeof value === 'string') {
      if (value === 'true') {
        return 'yes';
      }
      if (value === 'false') {
        return 'no';
      }
    }
    return value?.toString();
  }
}
