import { AfterViewInit, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Observable } from 'rxjs';
import { NewProjectSidebarComponent } from '../../components/new-project-sidebar/new-project-sidebar.component';
import { INewProjectDialogComponent } from '../../models/new-project-dialog-component.model';

@Component({
  selector: 'app-new-project',
  templateUrl: './new-project.component.html',
  styleUrls: ['./new-project.component.scss'],
})
export class NewProjectComponent implements AfterViewInit, INewProjectDialogComponent {
  @ViewChild(RouterOutlet) outlet: RouterOutlet;
  @ViewChild(NewProjectSidebarComponent) sidebar: NewProjectSidebarComponent;

  title$: Observable<string>;
  goBackHandler: () => void;
  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.sidebar?.setActiveStatus(this.outlet.activatedRoute.url);
  }

  onRouterItemChange(event: INewProjectDialogComponent) {
    // Some of the titles are dynamic and based on url params, hence the observable
    this.title$ = event.title$;
    this.sidebar?.setActiveStatus(this.outlet.activatedRoute.url);
    this.goBackHandler = event.goBack;
    this.changeDetectorRef.detectChanges();
  }

  closeWindow(): void {
    this.outlet.deactivate();
  }

  goBack = () => this.goBackHandler();
}
