import { Component, HostBinding, Input } from '@angular/core';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-status-badge',
  templateUrl: './status-badge.component.html',
  styleUrls: ['./status-badge.component.scss'],
})
export class StatusBadgeComponent {
  faCircle = faCircle;
  @Input()
  public status: string;
  @HostBinding('class') get className() {
    return this.status?.toLowerCase().replace(' ', '-');
  }
}
