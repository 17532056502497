// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
/*
  It applies to page with side bar.
  Example: systemParams -> systemParams.component.scss
*/
/*
  It applies to the title (header) of page content body.
*/
/*
  It describes common behavior on mouse hover.
*/
/*
  It describes light shadow for any page block.
*/
/*
  It applies to entity block like project, facility which has own title, edit button and body.
*/
/*
  It applies together with entity-card mixin, which has been described above
*/
/*
  It applies together with 'entity-card' mixin, which has been described above.
*/
/*
  It applies together with 'entity-card' mixin, which has been described above.
*/
.ic-question-option__option {
  position: relative;
  padding-left: 26px;
  /* Base for label styling */
  /* checkbox aspect */
  /* checked mark aspect */
  /* checked mark aspect changes */
  /* disabled checkbox */
  display: inline-block;
  font-weight: normal;
  font-size: 16px;
  font-family: GothamNarrowBook, sans-serif;
  margin-bottom: 8px;
}
.ic-question-option__option [type=checkbox]:not(:checked),
.ic-question-option__option [type=checkbox]:checked {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}
.ic-question-option__option [type=checkbox] + span {
  cursor: pointer;
}
.ic-question-option__option [type=checkbox]:not(:checked) + span:before,
.ic-question-option__option [type=checkbox]:checked + span:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #009dd9;
  background-color: #fff;
  border-radius: 2px;
  cursor: pointer;
}
.ic-question-option__option [type=checkbox]:not(:checked) + span:after,
.ic-question-option__option [type=checkbox]:checked + span:after {
  content: "✔";
  position: absolute;
  top: 0.2em;
  left: 0.25em;
  line-height: 0.8;
  color: #009dd9;
  cursor: pointer;
}
.ic-question-option__option [type=checkbox]:not(:checked) + span:after {
  opacity: 0;
}
.ic-question-option__option [type=checkbox]:checked + span:after {
  opacity: 1;
}
.ic-question-option__option [type=checkbox]:disabled + span {
  color: #c8c8c8;
  cursor: default;
  pointer-events: none;
}
.ic-question-option__option [type=checkbox]:disabled + span:before,
.ic-question-option__option [type=checkbox]:disabled + span:after {
  cursor: default;
  pointer-events: none;
}
.ic-question-option__option [type=checkbox]:disabled:not(:checked) + span:after {
  opacity: 0;
}
.ic-question-option__option [type=checkbox]:disabled:checked + span:after {
  opacity: 0.5;
}
.ic-question-option__option span:hover:before {
  background-color: #f4fbfe !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
