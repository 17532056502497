import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { IConfigAPIClient, ProjectViewModel } from 'src/app/shared/models/autogenerated';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { filter, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { GraphService } from 'src/app/core/services/graph-service';
import { Project } from 'src/app/project/models/project.model';
import { AddEditTeamDialogComponent } from '../../components/add-edit-team-dialog/add-edit-team-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AddEditProjectDialogComponent } from '../../components/add-edit-project-dialog/add-edit-project-dialog.component';
import { ProjectService } from 'src/app/shared/services/project.service';
import { DeleteConfirmationDialogComponent } from 'src/app/shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss'],
})
export class ProjectComponent implements OnInit, OnDestroy {
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private iconfigAPIClient: IConfigAPIClient,
    private projectService: ProjectService,
    private graphService: GraphService,
    public dialog: MatDialog
  ) {}
  projectId$ = this.activatedRoute.paramMap.pipe(
    map(params => params.get('projectId')),
    shareReplay(1)
  );

  private readonly subscription = new Subscription();
  readonly projectViewModel$ = this.getProjectViewModel();
  readonly projectModel$ = this.getProjectModel();
  public projectNgModel: Project;

  isShowingMembers: boolean;

  ngOnInit(): void {
    this.subscription.add(
      this.projectModel$.subscribe(model => {
        this.projectNgModel = Object.assign({}, model);
      })
    );

    this.subscription.add(
      this.router.events
        .pipe(
          filter(e => e instanceof NavigationEnd),
          map(_ => this.router.url.endsWith('members'))
        )
        .subscribe(result => (this.isShowingMembers = result))
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getProjectViewModel(): Observable<ProjectViewModel> {
    return this.projectId$.pipe(
      switchMap(projectId => this.projectService.getProject(projectId)),
      shareReplay(1)
    );
  }

  getProjectModel(): Observable<Project> {
    return this.projectViewModel$.pipe(
      switchMap(proj => combineLatest([of(proj), this.graphService.getUserByOid(proj.createdBy)])),
      map(([proj, user]) => ({ ...proj, createdByName: user ? `${user.givenName} ${user.surname}` : '' } as Project))
    );
  }

  addMember() {
    this.subscription.add(
      this.projectId$.pipe(take(1)).subscribe(id => {
        this.dialog.open(AddEditTeamDialogComponent, {
          panelClass: 'add-edit-team-dialog',
          disableClose: true,
          maxWidth: null, // by default the library sets it to 80vw
          data: { projectId: id },
        });
      })
    );
  }

  togglePublic() {
    this.projectNgModel.public = !this.projectNgModel.public;
    this.subscription.add(
      this.projectId$
        .pipe(
          take(1),
          switchMap(id => this.iconfigAPIClient.updateProject(id, this.projectNgModel)),
          tap(_ => {
            this.projectService.refreshProjectList(); // refresh view
          })
        )
        .subscribe()
    );
  }

  deleteProject() {
    this.subscription.add(
      this.dialog
        .open(DeleteConfirmationDialogComponent, {
          panelClass: 'delete-dialog',
          disableClose: true,
          data: { itemToDelete: this.projectNgModel.name },
        })
        .afterClosed()
        .pipe(
          filter(result => result),
          switchMap(_ => this.projectId$),
          switchMap(id => this.iconfigAPIClient.deleteProject(id)),
          tap(_ => {
            this.projectService.refreshProjectList();
            this.router.navigate(['/'], { relativeTo: this.activatedRoute, replaceUrl: true });
          })
        )
        .subscribe()
    );
  }

  editProject(project: ProjectViewModel) {
    this.dialog.open(AddEditProjectDialogComponent, {
      panelClass: 'edit-project-dialog',
      disableClose: true,
      maxWidth: null, // by default the library sets it to 80vw
      data: project,
    });
  }
}
