import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { MsalComponent } from './core/components/msal.component';
import { NotAuthorizedComponent } from './core/components/not-authorized/not-authorized.component';
import { RoleGuard } from './core/guards/role.guard';
import { ProjectSearchComponent } from './project/pages/search/project-search.component';
import { DashboardComponent } from './project/pages/dashboard/dashboard.component';

const defaultAuth = {
  data: {
    roles: ['User'],
  },
  canActivate: [MsalGuard, RoleGuard],
};

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    // Needed for hash routing
    path: 'code',
    component: MsalComponent,
  },
  {
    ...defaultAuth,
    path: 'project/:projectId/facility/:facilityId/system',
    loadChildren: () => import('src/app/system/system.module').then(m => m.SystemModule),
  },
  {
    ...defaultAuth,
    path: 'project',
    loadChildren: () => import('src/app/project/project.module').then(m => m.ProjectModule),
  },
  {
    ...defaultAuth,
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [MsalGuard],
  },
  {
    path: '403',
    component: NotAuthorizedComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'projectsearch',
    component: ProjectSearchComponent,
    canActivate: [MsalGuard],
  },
  {
    // TODO - remove this once we have more than a project page
    path: '**',
    redirectTo: 'dashboard',
  },
];

const isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    useHash: true,
    // Don't perform initial navigation in iframes
    initialNavigation: !isIframe ? 'enabledNonBlocking' : 'disabled',
    paramsInheritanceStrategy: 'always'
}),
  ],
  //   imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
