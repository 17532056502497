import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { filter, map, switchMap, throttleTime } from 'rxjs/operators';
import { UserService } from 'src/app/core/services/user.service';
import { NIL_UUID } from 'src/app/shared/constants';
import { IConfigAPIClient, ParameterCommentViewModel, ParameterViewModel } from 'src/app/shared/models/autogenerated';

@Component({
  selector: 'question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss'],
})
export class QuestionComponent implements OnInit, OnDestroy {
  NIL_UUID = NIL_UUID;
  isExpanded = false;

  userImage$: Observable<SafeUrl>;
  readonly currentUserPhoto$ = this.userService.currentUserPhoto$;
  readonly currentUserProfile$ = this.userService.currentUserProfile$;
  private readonly subscription = new Subscription();
  content = new UntypedFormControl('');
  private addCommentSubject = new Subject();

  @Input()
  public parameter: ParameterViewModel;
  public parameterLabel: string;

  @Output()
  public parameterChanged = new EventEmitter<ParameterViewModel>();

  constructor(private userService: UserService, private sanitizer: DomSanitizer, private iconfigAPIClient: IConfigAPIClient) {}

  ngOnInit(): void {
    this.parameterLabel = this.parameter.label + (this.parameter.unit ? ` ( ${this.parameter.unit} )` : '');
    this.userImage$ = this.currentUserPhoto$.pipe(
      filter(photo => photo != null),
      map(photo => URL.createObjectURL(photo)),
      map(objectURL => this.sanitizer.bypassSecurityTrustUrl(objectURL))
    );

    this.subscription.add(
      combineLatest([this.addCommentSubject, this.currentUserProfile$])
        .pipe(
          throttleTime(500),
          map(([_, profile]) => profile.idTokenClaims['oid']),
          map(
            id =>
              ({
                parameterId: this.parameter.parameterId,
                createdBy: id,
                content: this.content.value,
              } as ParameterCommentViewModel)
          ),
          switchMap(comment => this.iconfigAPIClient.createParameterComment(comment))
        )
        .subscribe(resp => {
          this.refreshQuestion();
          this.content.setValue('');
        })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  updateParameter(value: string): void {
    this.parameter.value = value;
    this.parameterChanged.emit(this.parameter);
  }

  addComment(): void {
    if (this.content.value != '') {
      this.addCommentSubject.next(this.content.value);
    }
  }

  refreshQuestion(): void {
    this.subscription.add(
      this.iconfigAPIClient.getParameterById(this.parameter.parameterId).subscribe(resp => {
        this.parameter = resp;
      })
    );
  }
}
