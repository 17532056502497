// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
  It applies to page with side bar.
  Example: systemParams -> systemParams.component.scss
*/
/*
  It applies to the title (header) of page content body.
*/
/*
  It describes common behavior on mouse hover.
*/
/*
  It describes light shadow for any page block.
*/
/*
  It applies to entity block like project, facility which has own title, edit button and body.
*/
/*
  It applies together with entity-card mixin, which has been described above
*/
/*
  It applies together with 'entity-card' mixin, which has been described above.
*/
/*
  It applies together with 'entity-card' mixin, which has been described above.
*/
.question {
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
}

.expanded-comments {
  font-size: 13px;
  margin-bottom: 30px;
  margin-right: 20px;
  box-shadow: 0px 5px 20px -4px #6b6d6f;
  margin-bottom: 0;
  margin-right: 0;
  padding: 20px;
}

.comments-header {
  display: flex;
  justify-content: space-between;
  font-weight: normal;
  font-size: 16px;
  font-family: GothamBold, sans-serif;
}

.add-comment {
  font-weight: normal;
  font-size: 16px;
  font-family: GothamNarrowBook, sans-serif;
  margin-top: 15px;
  display: flex;
}

.user-image-container {
  display: flex;
  align-self: flex-start;
}

.user-photo-placeholder {
  width: 40px;
  height: 40px;
  font-size: 40px;
  margin-right: 10px;
}

.user-photo {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin-right: 10px;
}

.add-comment-content {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.textarea {
  padding: 5px;
  border: 2px solid #c8c8c8;
  color: #212121;
  outline: none;
  border-radius: 0;
  transition: border-color 0.2s ease;
}
.textarea[disabled] {
  border-color: #c8c8c8;
  background-color: #fff;
  color: #8c8f93;
}
.textarea:focus {
  border-color: #0066b2;
}
.textarea.warning {
  border-color: #da9c36;
}
.textarea.ng-dirty.ng-invalid, .textarea.invalid {
  border-color: #e21836;
}
.textarea::placeholder {
  color: #6b6d6f;
}

.add-comment-button {
  align-self: flex-end;
  margin-top: 15px;
}

.show-comments {
  font-weight: normal;
  font-size: 16px;
  font-family: GothamNarrowBook, sans-serif;
  display: inline-block;
  border-width: 0;
  border-radius: 0;
  cursor: pointer;
  min-height: 30px;
  font-weight: bold;
  line-height: 22px;
  font-family: GothamBook, sans-serif;
  background-color: transparent;
  color: #0066b2;
  color: #0066b2;
  white-space: nowrap;
}
.show-comments.minw250 {
  min-width: 250px;
}
.show-comments.minw200 {
  min-width: 200px;
}
.show-comments.minw150 {
  min-width: 150px;
}
.show-comments.minw100 {
  min-width: 100px;
}
.show-comments.minw50 {
  min-width: 50px;
}
.show-comments.minh40 {
  min-height: 40px;
}
.show-comments:focus {
  outline: none;
}
.show-comments mat-icon {
  vertical-align: middle;
}
.show-comments.active {
  color: #0b2d71;
  background-color: #f7f7f7;
}
.show-comments.margin-border {
  border: 2px solid #0066b2;
  margin: 5px 15px 5px 0;
}
.show-comments.selected {
  color: #fff;
  background-color: #0066b2;
}
.show-comments:disabled {
  cursor: default;
  pointer-events: none;
  background-color: transparent;
  color: #c8c8c8;
}

.question .show-comments-hidden {
  font-weight: normal;
  font-size: 16px;
  font-family: GothamNarrowBook, sans-serif;
  display: inline-block;
  border-width: 0;
  border-radius: 0;
  cursor: pointer;
  min-height: 30px;
  font-weight: bold;
  line-height: 22px;
  font-family: GothamBook, sans-serif;
  background-color: transparent;
  color: #0066b2;
  color: #0066b2;
  visibility: hidden;
}
.question .show-comments-hidden.minw250 {
  min-width: 250px;
}
.question .show-comments-hidden.minw200 {
  min-width: 200px;
}
.question .show-comments-hidden.minw150 {
  min-width: 150px;
}
.question .show-comments-hidden.minw100 {
  min-width: 100px;
}
.question .show-comments-hidden.minw50 {
  min-width: 50px;
}
.question .show-comments-hidden.minh40 {
  min-height: 40px;
}
.question .show-comments-hidden:focus {
  outline: none;
}
.question .show-comments-hidden mat-icon {
  vertical-align: middle;
}
.question .show-comments-hidden.active {
  color: #0b2d71;
  background-color: #f7f7f7;
}
.question .show-comments-hidden.margin-border {
  border: 2px solid #0066b2;
  margin: 5px 15px 5px 0;
}
.question .show-comments-hidden.selected {
  color: #fff;
  background-color: #0066b2;
}
.question .show-comments-hidden:disabled {
  cursor: default;
  pointer-events: none;
  background-color: transparent;
  color: #c8c8c8;
}

.question:hover .show-comments-hidden {
  visibility: visible;
}

.close-comments {
  font-weight: normal;
  font-size: 16px;
  font-family: GothamNarrowBook, sans-serif;
  display: inline-block;
  border-width: 0;
  border-radius: 0;
  cursor: pointer;
  min-height: 30px;
  font-weight: bold;
  line-height: 22px;
  font-family: GothamBook, sans-serif;
  background-color: transparent;
  color: #0066b2;
  margin-right: 10px;
  color: #0066b2;
}
.close-comments.minw250 {
  min-width: 250px;
}
.close-comments.minw200 {
  min-width: 200px;
}
.close-comments.minw150 {
  min-width: 150px;
}
.close-comments.minw100 {
  min-width: 100px;
}
.close-comments.minw50 {
  min-width: 50px;
}
.close-comments.minh40 {
  min-height: 40px;
}
.close-comments:focus {
  outline: none;
}
.close-comments mat-icon {
  vertical-align: middle;
}
.close-comments.active {
  color: #0b2d71;
  background-color: #f7f7f7;
}
.close-comments.margin-border {
  border: 2px solid #0066b2;
  margin: 5px 15px 5px 0;
}
.close-comments.selected {
  color: #fff;
  background-color: #0066b2;
}
.close-comments:disabled {
  cursor: default;
  pointer-events: none;
  background-color: transparent;
  color: #c8c8c8;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
