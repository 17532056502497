import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-placeholder',
  templateUrl: './placeholder.component.html',
  styleUrls: ['./placeholder.component.scss'],
})
export class PlaceholderComponent {
  @Input()
  public title: string;

  @Input()
  public description: string;

  @Input()
  public buttonCaption: string;

  @Input()
  public icon: string;

  @Output()
  buttonClick = new EventEmitter();
}
