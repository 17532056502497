import { Component, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { FilterModel } from 'src/app/shared/models/filter.model';
import { DeleteConfirmationDialogComponent } from 'src/app/shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { FacilityViewModel, SystemViewModel } from 'src/app/shared/models/autogenerated';
import { AddEditFacilityDialogComponent } from '../../components/add-edit-facility-dialog/add-edit-facility-dialog.component';
import { FacilityService } from '../../services/facility.service';
import { System } from '../../models/system.model';

@Component({
  selector: 'app-facility',
  templateUrl: './facility.component.html',
  styleUrls: ['./facility.component.scss'],
})
export class FacilityComponent implements OnDestroy {
  constructor(
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private facilityService: FacilityService,
    private router: Router
  ) {}

  facility$ = this.facilityService.getFacilityForCurrentRoute(this.activatedRoute).pipe(shareReplay(1));

  filterModels: FilterModel[] = [{ label: 'status', displayPropertyName: 'status', filterProperty: 'status' }];

  private readonly subscription = new Subscription();
  systems$ = this.getSystems();
  selectedSystemIds: string[];
  selectedSystems: System[] = [];

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  navigateToSystemSelection() {
    this.router.navigate(['system-selection'], { relativeTo: this.activatedRoute });
  }

  getSystems(): Observable<System[]> {
    return this.facility$.pipe(
      switchMap(facility => this.facilityService.getSystemsInFacility(facility.id)),
      map(systems =>
        systems.map(system => {
          return { ...system, selected: false } as System;
        })
      ),
      shareReplay(1)
    );
  }

  onSystemsSelected(systems: System[]) {
    this.selectedSystems = systems.filter(system => system.selected);
    this.selectedSystemIds = this.selectedSystems.map(system => system.id); //.join(',');
  }

  editFacility(facility: FacilityViewModel) {
    this.dialog.open(AddEditFacilityDialogComponent, {
      panelClass: 'add-edit-facility-dialog',
      disableClose: true,
      data: facility,
      maxWidth: null, // by default the library sets it to 80vw
    });
  }

  deleteFacility(facility: FacilityViewModel) {
    this.subscription.add(
      this.dialog
        .open(DeleteConfirmationDialogComponent, {
          panelClass: 'delete-dialog',
          disableClose: true,
          data: { itemToDelete: facility.name },
        })
        .afterClosed()
        .pipe(
          filter(result => result),
          switchMap(_ => this.facilityService.deleteFacility(facility.id)),
          tap(_ => {
            this.facilityService.refreshFacility();
            this.router.navigate(['../'], { relativeTo: this.activatedRoute });
          })
        )
        .subscribe()
    );
  }
}
