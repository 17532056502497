import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard  {
  constructor(private userService: UserService, private router: Router) {}
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const allowedRoles = next.data.roles;
    return this.userService.roles$.pipe(
      map(userRoles => userRoles.filter(userRole => allowedRoles.includes(userRole)).length > 0),
      tap(isAuthorized => {
        if (!isAuthorized) {
          this.router.navigate(['403']);
        }
      })
    );
  }
}
