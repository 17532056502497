import { EventEmitter, Input, Output } from '@angular/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { IConfigAPIClient, ProjectViewModel } from 'src/app/shared/models/autogenerated';
import { ProjectService } from 'src/app/shared/services/project.service';

@Component({
  selector: 'app-add-edit-project',
  templateUrl: './add-edit-project.component.html',
  styleUrls: ['./add-edit-project.component.scss'],
})
export class AddEditProjectComponent implements OnInit, OnDestroy {
  private readonly subscription = new Subscription();
  readonly businessUnits$ = this.getBusinessUnits();

  readonly geographicRegions$ = this.getGeographicRegions();
  private geographicRegionSubject = new BehaviorSubject<string>('');
  readonly geographicRegion$ = this.geographicRegionSubject.asObservable();

  readonly allCountries$ = this.getAllCountries();
  readonly countries$ = this.getFilteredCountries();
  private countrySubject = new BehaviorSubject<string>('');
  readonly country$ = this.countrySubject.asObservable();

  readonly allLocations$ = this.getAllGeographicLocations();
  readonly geographicLocations$ = this.getFilterdGeographicLocations();
  FORM_DEFAULT_STR = '';

  projectForm = new UntypedFormGroup({
    public: new UntypedFormControl(false, [Validators.required]),
    name: new UntypedFormControl(this.FORM_DEFAULT_STR, [Validators.required]),
    description: new UntypedFormControl(this.FORM_DEFAULT_STR),
    businessUnit: new UntypedFormControl(this.FORM_DEFAULT_STR, [Validators.required]),
    region: new UntypedFormControl(this.FORM_DEFAULT_STR, [Validators.required]),
    country: new UntypedFormControl(this.FORM_DEFAULT_STR, [Validators.required]),
    location: new UntypedFormControl(this.FORM_DEFAULT_STR, [Validators.required]),
  });

  @Input()
  public project: ProjectViewModel;
  @Input()
  public submitText: string;

  @Output()
  public projectSubmitted = new EventEmitter<ProjectViewModel>();
  @Output()
  public cancel = new EventEmitter();

  constructor(private iConfigAPIClient: IConfigAPIClient, private projectService: ProjectService) {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.projectForm.patchValue(this.project);
  }

  public Submit() {
    if (this.projectForm.invalid) {
      return;
    }

    this.project = { ...this.project, ...this.projectForm.value };
    this.projectSubmitted.emit(this.project);
  }

  setPrivate() {
    this.projectForm.get('public').patchValue(false);
  }

  setPublic() {
    this.projectForm.get('public').patchValue(true);
  }

  isPrivate() {
    return this.projectForm.get('public').value === false;
  }

  isPublic() {
    return this.projectForm.get('public').value === true;
  }

  getBusinessUnits() {
    return this.iConfigAPIClient.getAllBusinessUnits().pipe(map(businessUnits => businessUnits.map(bu => bu.name)));
  }

  getGeographicRegions() {
    return this.iConfigAPIClient.getAllGeographicRegions().pipe(map(geographicRegions => geographicRegions.map(region => region.name)));
  }

  getAllCountries() {
    return this.iConfigAPIClient.getAllCountries();
  }

  getAllGeographicLocations() {
    return this.iConfigAPIClient.getAllGeographicLocations();
  }

  regionUpdated() {
    this.projectForm.patchValue({ country: this.FORM_DEFAULT_STR });
    this.geographicRegionSubject.next(this.projectForm.get('region').value);
  }

  countryUpdated() {
    this.projectForm.patchValue({ location: this.FORM_DEFAULT_STR });
    this.countrySubject.next(this.projectForm.get('country').value);
  }

  getFilteredCountries() {
    return combineLatest([this.allCountries$, this.geographicRegion$]).pipe(
      map(([countries, region]) => {
        if (region === this.FORM_DEFAULT_STR) {
          return countries;
        } else {
          return countries.filter(country => country.geographicRegion.name === region);
        }
      }),
      map(countries => countries.map(country => country.name))
    );
  }

  getFilterdGeographicLocations() {
    return combineLatest([this.allLocations$, this.country$, this.geographicRegion$]).pipe(
      map(([locations, country, _]) => {
        if (country === this.FORM_DEFAULT_STR) {
          return locations;
        } else {
          return locations.filter(location => location.country.name === country);
        }
      }),
      map(locations => locations.map(location => location.name))
    );
  }
}
