import { Pipe, PipeTransform } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Pipe({
  name: 'ArrayToDatasource',
})
export class ArrayToDatasourcePipe implements PipeTransform {
  transform<T>(value: T[], sort?: Sort): MatTableDataSource<T> {
    // Need to deep clone to leave original array as it is
    const arrayCopy: T[] = JSON.parse(JSON.stringify(value));
    if (sort && sort.direction) {
      arrayCopy.sort((a, b) =>
        sort.direction === 'asc' ? a[sort.active].localeCompare(b[sort.active]) : b[sort.active].localeCompare(a[sort.active])
      );
    }
    return new MatTableDataSource(arrayCopy ?? []);
  }
}
