import { IConfigAPIClient, SystemViewModel } from 'src/app/shared/models/autogenerated';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, take, tap, throttleTime } from 'rxjs/operators';
import { concatMap, map, shareReplay, switchMap } from 'rxjs/operators';
import { TemplatePortal } from '@angular/cdk/portal';
import { FooterService } from 'src/app/core/services/footer.service';
import { HeaderService } from 'src/app/core/services/header.service';
import { toggleLoading } from 'src/app/shared/utils/loading';

@Component({
  selector: 'app-system-selection',
  templateUrl: './system-selection.component.html',
  styleUrls: ['./system-selection.component.scss'],
})
export class SystemSelectionComponent implements OnInit, OnDestroy {
  constructor(
    private activatedRoute: ActivatedRoute,
    private iconfigAPIClient: IConfigAPIClient,
    private router: Router,
    private footerService: FooterService,
    private headerService: HeaderService,
    private viewContainerRef: ViewContainerRef
  ) {}

  private readonly subscription = new Subscription();

  @ViewChild('footerTemplate', { static: true }) footerTemplate: TemplateRef<any>;
  @ViewChild('headerTemplate', { static: true }) headerTemplate: TemplateRef<any>;

  facilityId$ = this.activatedRoute.paramMap.pipe(map(params => params.get('facilityId')));

  isLoadingSubject = new BehaviorSubject<boolean>(true);
  showSpinner$ = of(true).pipe(toggleLoading(this.isLoadingSubject));

  private updateSystemsSubject = new BehaviorSubject<void>(void 0);
  private updateSystems$ = this.updateSystemsSubject.asObservable();
  private availableSystems$ = this.getAvailableSystems();
  selectedSystems$ = this.getSelectedSystems();
  unselectedSystems$ = this.getUnselectedSystems();

  private selectSystemsSubject = new Subject<SystemViewModel[]>();
  private selectSystems$ = this.selectSystemsSubject.asObservable();
  private removeSystemsSubject = new Subject<SystemViewModel[]>();
  private removeSystems$ = this.removeSystemsSubject.asObservable();

  selectedProcessSystems$ = this.getSelectedProcessSystems();
  unselectedProcessSystems$ = this.getUnselectedProcessSystems();
  selectedUtilitySystems$ = this.getSelectedUtilitySystems();
  unselectedUtilitySystems$ = this.getUnselectedUtilitySystems();

  private initialSelection: SystemViewModel[];
  public systemsToConfigure: SystemViewModel[] = [];
  public systemsToConfigureRouterLink: any[];
  public systemsToConfigureQueryParams: Params;

  ngOnInit(): void {
    this.subscription.add(
      combineLatest([this.facilityId$, this.selectSystems$])
        .pipe(
          throttleTime(500),
          concatMap(([facilityId, systems]) =>
            this.iconfigAPIClient.createSystemsByCode(
              facilityId,
              systems.map(s => s.code)
            )
          ),
          catchError(error => {
            this.isLoadingSubject.next(false);
            throw error;
          })
        )
        .subscribe(() => this.updateSystemsSubject.next())
    );

    this.subscription.add(
      this.removeSystems$
        .pipe(
          throttleTime(500),
          concatMap(systems => this.iconfigAPIClient.deleteSystems(systems.map(s => s.id))),
          catchError(error => {
            this.isLoadingSubject.next(false);
            throw error;
          })
        )
        .subscribe(() => this.updateSystemsSubject.next())
    );

    this.subscription.add(
      combineLatest([this.unselectedSystems$, this.selectedSystems$])
        .pipe(tap(([_, __]) => this.isLoadingSubject.next(false)))
        .subscribe()
    );

    this.subscription.add(this.selectedSystems$.pipe(take(1)).subscribe(selectedSystems => (this.initialSelection = selectedSystems)));

    this.subscription.add(
      this.selectedSystems$.subscribe(selectedSystems => {
        this.systemsToConfigure = selectedSystems.filter(s => !this.initialSelection.map(x => x.code).includes(s.code));
        const systemsToConfigureIds = this.systemsToConfigure.map(s => s.id);
        this.systemsToConfigureRouterLink = ['..', 'system', systemsToConfigureIds[0], 'questionnaire'];
        if (systemsToConfigureIds.length >= 2) {
          this.systemsToConfigureQueryParams = { systems: systemsToConfigureIds };
        } else {
          this.systemsToConfigureQueryParams = {};
        }
      })
    );

    this.footerService.setFooterPortal(new TemplatePortal(this.footerTemplate, this.viewContainerRef));
    this.headerService.setHeaderPortal(new TemplatePortal(this.headerTemplate, this.viewContainerRef));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.footerService.clearFooterPortal();
    this.headerService.clearHeaderPortal();
  }

  getSelectedSystems(): Observable<SystemViewModel[]> {
    return combineLatest([this.facilityId$, this.updateSystems$]).pipe(
      switchMap(([facilityId, _]) => this.iconfigAPIClient.getSystemsInFacility(facilityId)),
      catchError(error => {
        this.isLoadingSubject.next(false);
        throw error;
      }),
      shareReplay()
    );
  }

  getAvailableSystems(): Observable<SystemViewModel[]> {
    return combineLatest([this.facilityId$, this.updateSystems$]).pipe(
      switchMap(([facilityId, _]) => this.iconfigAPIClient.getAvailableSystemsForFacility(facilityId)),
      catchError(error => {
        this.isLoadingSubject.next(false);
        throw error;
      }),
      shareReplay()
    );
  }

  public onSelectSystem(system: SystemViewModel): void {
    this.isLoadingSubject.next(true);
    this.selectSystemsSubject.next([system]);
  }

  public onRemoveSystem(system: SystemViewModel): void {
    this.isLoadingSubject.next(true);
    this.removeSystemsSubject.next([system]);
  }

  public onSelectAllSystems(systems: SystemViewModel[]): void {
    if (systems === null) {
      return;
    }

    this.isLoadingSubject.next(true);
    this.selectSystemsSubject.next(systems);
  }

  public onRemoveAllSystems(systems: SystemViewModel[]): void {
    if (systems === null) {
      return;
    }
    this.isLoadingSubject.next(true);
    this.removeSystemsSubject.next(systems);
  }

  getSelectedProcessSystems(): Observable<SystemViewModel[]> {
    return this.selectedSystems$.pipe(map(results => results.filter(s => s.group === 'Process')));
  }

  getSelectedUtilitySystems(): Observable<SystemViewModel[]> {
    return this.selectedSystems$.pipe(map(results => results.filter(s => s.group === 'Utility')));
  }

  getUnselectedSystems(): Observable<SystemViewModel[]> {
    return combineLatest([this.selectedSystems$, this.availableSystems$]).pipe(
      map(([selected, availableSystems]) => availableSystems.filter(sn => !selected.map(s => s.code).includes(sn.code)))
    );
  }

  getUnselectedProcessSystems(): Observable<SystemViewModel[]> {
    return this.unselectedSystems$.pipe(map(results => results.filter(s => s.group === 'Process')));
  }

  getUnselectedUtilitySystems(): Observable<SystemViewModel[]> {
    return this.unselectedSystems$.pipe(map(results => results.filter(s => s.group === 'Utility')));
  }

  close() {
    this.router.navigate(['..'], { relativeTo: this.activatedRoute });
  }
}
