import { EventEmitter, OnDestroy, Output } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FilterModel } from '../../../shared/models/filter.model';
import { Project } from '../../models/project.model';

@Component({
  selector: 'app-project-filter',
  templateUrl: './project-filter.component.html',
  styleUrls: ['./project-filter.component.scss'],
})
export class ProjectFilterComponent implements OnInit, OnDestroy {
  isExpanded = false;

  @Output() showGridview = new EventEmitter<boolean>();

  @Input()
  filters: FilterModel[];

  @Input()
  projects: Project[];
  filterValues: Params;

  @Input()
  displaySearchField: boolean;
  private readonly subscription = new Subscription();

  constructor(private activatedRoute: ActivatedRoute, private router: Router) {}
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    this.subscription.add(
      this.activatedRoute.queryParams.subscribe(params => {
        this.filterValues = Object.keys(params)
          .filter(paramName => this.filters.map(filter => filter.filterProperty).includes(paramName))
          .reduce((obj, key) => {
            obj[key] = params[key];
            return obj;
          }, {});
      })
    );
  }

  setFilterValue(value: string, prop: string) {
    // Remove all filters depended on selected filter
    const queryParams = Object.keys(this.filterValues).reduce<Params>(
      (acc, curr) => (Object.keys(acc).includes(prop) ? { ...acc, [curr]: null } : { ...acc, [curr]: this.filterValues[curr] }),
      {}
    );

    queryParams[prop] = value;

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams,
      queryParamsHandling: 'merge',
    });
  }

  onSearchProjects(searchTerm: string): void {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { name: searchTerm ? searchTerm : null },
      queryParamsHandling: 'merge',
    });
  }
}
