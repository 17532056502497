import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MsalComponent } from './components/msal.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SharedModule } from '../shared/shared.module';
import { MatIconModule } from '@angular/material/icon';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { PortalModule } from '@angular/cdk/portal';
import { DefaultFooterComponent } from './components/footer/default-footer/default-footer.component';
import { DefaultHeaderComponent } from './components/header/default-header/default-header.component';
import { UserButtonComponent } from './components/header/user-button/user-button.component';
import { NotAuthorizedComponent } from './components/not-authorized/not-authorized.component';
@NgModule({
  declarations: [
    MsalComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    DefaultFooterComponent,
    DefaultHeaderComponent,
    UserButtonComponent,
    NotAuthorizedComponent,
  ],
  imports: [CommonModule, RouterModule, SharedModule, MatIconModule, PortalModule],
  exports: [MsalComponent, HeaderComponent, FooterComponent, SidebarComponent, UserButtonComponent],
})
export class CoreModule {}
