import { EventEmitter, OnDestroy, Output } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { IConfigAPIClient } from 'src/app/shared/models/autogenerated';
import { TeamMember } from '../../models/teamMember.model';

@Component({
  selector: 'app-search-edit-members',
  templateUrl: './search-edit-members.component.html',
  styleUrls: ['./search-edit-members.component.scss'],
})
export class SearchEditMembersComponent implements OnInit, OnDestroy {
  private readonly subscription = new Subscription();
  @Input() teamMembers: TeamMember[];
  @Input() newProject: boolean;
  @Input() hideHeader = false;
  @Input() isReadOnly = false;

  @Output() delete: EventEmitter<TeamMember> = new EventEmitter();
  @Output() updated = new EventEmitter();

  readonly roleTitles$: Observable<string[]> = this.iConfigApiClient.getAllUserProjectRoles();
  searchText: string;
  currentSearch: string;
  roleTitles: string[];

  constructor(private iConfigApiClient: IConfigAPIClient) {}

  onKeyup(code: string) {
    if (code === 'Enter') {
      this.currentSearch = this.searchText;
    }
  }
  clearSearch() {
    this.currentSearch = '';
    this.searchText = '';
  }
  ngOnInit(): void {
    this.subscription.add(this.roleTitles$.subscribe(roles => (this.roleTitles = roles)));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
