import { Component, EventEmitter, Input, Output } from '@angular/core';
import { yesNoOptions } from '../../constants/yesno-options.constant';
import { YesNoOption } from '../../models/yesno-option.model';

@Component({
  selector: 'question-yesno-buttons',
  templateUrl: './question-yesno-buttons.component.html',
  styleUrls: ['./question-yesno-buttons.component.scss'],
})
export class QuestionYesNoButtonsComponent {
  @Input()
  public title: string;

  @Input()
  public value: boolean;

  @Output()
  public valueChanged = new EventEmitter<string>();

  public options: YesNoOption[] = yesNoOptions;

  public onValueChange(option: YesNoOption): void {
    this.value = option.value;
    this.valueChanged.emit(this.value.toString());
  }

  public isCurrentAnswer(option: YesNoOption): boolean {
    return this.value === option.value;
  }
}
