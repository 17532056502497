import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { catchError, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { IConfigAPIClient, ParameterViewModel, RecommendationViewModel } from 'src/app/shared/models/autogenerated';
import { FileService } from 'src/app/shared/services/file.service';
import { faDownload, faExternalLinkAlt, faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { toggleLoading } from 'src/app/shared/utils/loading';

@Component({
  selector: 'app-recommendation',
  templateUrl: './recommendation.component.html',
  styleUrls: ['./recommendation.component.scss'],
})
export class RecommendationComponent implements OnInit, OnDestroy {
  constructor(private iconfigAPIClient: IConfigAPIClient, private fileService: FileService, private activatedRoute: ActivatedRoute) {}

  public recommendation$ = new Observable<RecommendationViewModel>();
  public parameters$ = new Observable<ParameterViewModel[]>();

  private systemId$ = new Observable<string>();
  private systemConfigId$ = new Observable<string>();
  filter$ = new Observable<string>();

  public groups: Set<string>;
  public faExternalLinkAlt = faExternalLinkAlt;
  public faDownload = faDownload;
  public faFileAlt = faFileAlt;
  private readonly subscription = new Subscription();

  isLoadingFileSubject = new BehaviorSubject<boolean>(false);
  showSpinner$ = of(true).pipe(toggleLoading(this.isLoadingFileSubject));

  ngOnInit(): void {
    this.systemId$ = this.activatedRoute.paramMap.pipe(map(params => params.get('systemId')));
    this.filter$ = this.activatedRoute.queryParamMap.pipe(map(params => params.get('filter') ?? 'summary'));

    this.systemConfigId$ = this.systemId$.pipe(
      switchMap(systemId => this.iconfigAPIClient.getSystemById(systemId)),
      map(system => system.code)
    );

    // TODO: need to modify if parameters are not the same as the system questionaire
    this.parameters$ = this.systemId$.pipe(
      switchMap(systemId => this.iconfigAPIClient.getQuestionnaireBySystemId(systemId)),
      shareReplay(1)
    );

    this.recommendation$ = this.systemId$.pipe(
      switchMap(systemId => this.iconfigAPIClient.getRecommendation(systemId)),
      shareReplay(1)
    );

    this.recommendation$.forEach(r => {
      this.groups = new Set();
      r.notes?.forEach(n => this.groups.add(n.group));
    });
  }

  downloadFile(downloadFunction: Function, id$: Observable<string>) {
    this.isLoadingFileSubject.next(true);
    this.subscription.add(
      id$
        .pipe(
          take(1),
          switchMap(systemId => downloadFunction(systemId)),
          tap(_ => this.isLoadingFileSubject.next(false)),
          catchError(error => {
            this.isLoadingFileSubject.next(false);
            throw error;
          })
        )
        .subscribe()
    );
  }

  downloadPfd(code: string) {
    this.downloadFile(_ => this.fileService.downloadPfd(code), this.systemId$);
  }

  openPfd(code: string) {
    this.downloadFile(_ => this.fileService.openPfd(code), this.systemId$);
  }

  downloadAllPfds() {
    this.downloadFile(systemId => this.fileService.downloadAllPfds(systemId), this.systemId$);
  }

  downloadNarrative() {
    this.downloadFile(configId => this.fileService.downloadNarrative(configId), this.systemConfigId$);
  }

  downloadSummary() {
    this.downloadFile(systemId => this.fileService.downloadSummary(systemId), this.systemId$);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
