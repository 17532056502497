import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { filter, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { AddEditFacilityDialogComponent } from 'src/app/facility/components/add-edit-facility-dialog/add-edit-facility-dialog.component';
import { FacilityService } from 'src/app/facility/services/facility.service';
import { FacilityViewModel } from 'src/app/shared/models/autogenerated';

@Component({
  selector: 'app-project-facilities',
  templateUrl: './project-facilities.component.html',
  styleUrls: ['./project-facilities.component.scss'],
})
export class ProjectFacilitiesComponent implements OnInit, OnDestroy {
  projectId$ = this.activatedRoute.paramMap.pipe(
    map(params => params.get('projectId')),
    shareReplay(1)
  );
  readonly facilities$: Observable<FacilityViewModel[]> = this.getFacilityInfo();
  private readonly subscription = new Subscription();

  constructor(
    private facilityService: FacilityService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog
  ) {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    this.subscription.add(this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(_ => this.routeToFacility()));
    this.routeToFacility();
  }

  private routeToFacility() {
    this.subscription.add(
      this.facilities$
        .pipe(
          tap(facilities => {
            // we need to use firstChild twice here, since the routing definition has deeper
            // nesting between project and facility routing modules
            // Also, we can use just the snapshot instead of subscribing to the whole observable
            // because we need this information just once
            const facilityId = this.activatedRoute.firstChild?.firstChild?.snapshot.params.facilityId;
            if (facilityId == null && facilities.length) {
              const path = this.router.url.includes('facility') ? [facilities[0].id] : ['facility', facilities[0].id];
              this.router.navigate(path, { relativeTo: this.activatedRoute, replaceUrl: true });
            }
          })
        )
        .subscribe()
    );
  }

  getFacilityInfo(): Observable<FacilityViewModel[]> {
    return this.projectId$.pipe(
      switchMap(projectId => this.facilityService.getFacilitiesInProject(projectId)),
      shareReplay(1)
    );
  }

  addFacility() {
    this.subscription.add(
      this.projectId$.pipe(take(1)).subscribe(id => {
        this.dialog.open(AddEditFacilityDialogComponent, {
          panelClass: 'add-edit-facility-dialog',
          disableClose: true,
          maxWidth: null, // by default the library sets it to 80vw
          data: { projectId: id },
        });
      })
    );
  }
}
