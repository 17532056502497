import { CdkPortalOutlet, Portal, PortalOutlet } from '@angular/cdk/portal';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { FooterService } from '../../services/footer.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnDestroy, OnInit {
  @ViewChild(CdkPortalOutlet, { static: true })
  portalOutlet: PortalOutlet;

  private readonly subscription = new Subscription();

  constructor(private footerService: FooterService) {}

  ngOnInit(): void {
    this.subscription.add(
      this.footerService.footerPortal$.subscribe((portal: Portal<any>) => {
        if (portal != null) {
          this.portalOutlet.detach();
          this.portalOutlet.attach(portal);
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
