import { SharedModule } from './../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FacilityComponent } from './pages/facility/facility.component';
import { FacilityRoutingModule } from './facility-routing.module';
import { SystemCardComponent } from './components/system-card/system-card.component';
import { SystemListComponent } from './components/system-list/system-list.component';
import { SystemSelectionComponent } from './pages/system-selection/system-selection.component';
import {MatButtonModule} from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { FacilityOverviewComponent } from './components/facility-overview/facility-overview.component';
import { SystemTableComponent } from './components/system-table/system-table.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddEditFacilityDialogComponent } from './components/add-edit-facility-dialog/add-edit-facility-dialog.component';
import { AddEditFacilityComponent } from './components/add-edit-facility/add-edit-facility.component';
import { SystemSelectionFooterComponent } from './components/system-selection-footer/system-selection-footer.component';
import { SystemSelectionHeaderComponent } from './components/system-selection-header/system-selection-header.component';
import { CoreModule } from '../core/core.module';

@NgModule({
  declarations: [
    FacilityComponent,
    SystemCardComponent,
    SystemListComponent,
    SystemSelectionComponent,
    FacilityOverviewComponent,
    SystemTableComponent,
    AddEditFacilityDialogComponent,
    AddEditFacilityComponent,
    SystemSelectionFooterComponent,
    SystemSelectionHeaderComponent,
  ],
  imports: [
    CommonModule,
    FacilityRoutingModule,
    MatDialogModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatMenuModule,
    MatSelectModule,
    MatTableModule,
    MatButtonModule,
    MatSortModule,
    MatIconModule,
    SharedModule,
    CoreModule,
  ],
  exports: [AddEditFacilityComponent],
})
export class FacilityModule {}
