// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
  It applies to page with side bar.
  Example: systemParams -> systemParams.component.scss
*/
/*
  It applies to the title (header) of page content body.
*/
/*
  It describes common behavior on mouse hover.
*/
/*
  It describes light shadow for any page block.
*/
/*
  It applies to entity block like project, facility which has own title, edit button and body.
*/
/*
  It applies together with entity-card mixin, which has been described above
*/
/*
  It applies together with 'entity-card' mixin, which has been described above.
*/
/*
  It applies together with 'entity-card' mixin, which has been described above.
*/
.list {
  width: 100%;
}
.list .header {
  display: flex;
  gap: 10px;
  padding: 5px;
  align-items: center;
}
.list .header .action {
  font-weight: normal;
  font-size: 16px;
  font-family: GothamBold, sans-serif;
  color: #0066b2;
  line-height: normal;
}
.list .header .title {
  font-weight: normal;
  font-size: 16px;
  font-family: GothamBold, sans-serif;
}
.list .body {
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}
.list .body .item {
  min-width: 200px;
}

.utility-color {
  color: #00708a;
}

.process-color {
  color: #ee602d;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
