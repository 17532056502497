import { Injectable } from '@angular/core';
import { IConfigAPIClient, ProjectTeamMemberAssignmentViewModel } from 'src/app/shared/models/autogenerated';
import { TeamMember } from '../models/teamMember.model';
import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { GraphService } from 'src/app/core/services/graph-service';

@Injectable({
  providedIn: 'root',
})
export class TeamMembersService {
  constructor(private iConfigApiClient: IConfigAPIClient, private graphService: GraphService) {}

  public getTeamMembers(projectId: string): Observable<TeamMember[]> {
    return this.iConfigApiClient.getProjectTeamMemberAssignments(projectId).pipe(
      map(assignments => ({ oids: assignments.map(assignment => assignment.azureOid), assignments })),
      switchMap(obj => combineLatest([this.graphService.getUsersByOids(obj.oids), of(obj.assignments)])),
      map(([members, assignments]) => this.combineAssignmentsAndMembers(members, assignments)),
      map(members => this.sortTeamMembers(members))
    );
  }

  public sortTeamMembers(members: TeamMember[]): TeamMember[] {
    return members.sort((a, b) => {
      const nameA = a.displayName.toLowerCase();
      const nameB = b.displayName.toLowerCase();
      if (nameA < nameB) {
        return -1;
      } else if (nameA > nameB) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  private combineAssignmentsAndMembers(members: MicrosoftGraph.User[], assignments: ProjectTeamMemberAssignmentViewModel[]): TeamMember[] {
    return members.map(member => {
      const assignment = assignments.find(a => a.azureOid === member.id);
      return new TeamMember({
        ...assignment,
        displayName: member.displayName,
        userPrincipalName: member.userPrincipalName,
      });
    });
  }
}
