import { Component, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { of, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { INewProjectDialogComponent } from '../../models/new-project-dialog-component.model';
import { NEW_PROJECT_DIALOG_OUTLET } from '../../new-project-flow.constants';
import { NewProjectComponent } from '../new-project/new-project.component';

@Component({
  selector: 'app-project-created-confirmation',
  templateUrl: './project-created-confirmation.component.html',
  styleUrls: ['./project-created-confirmation.component.scss'],
})
export class ProjectCreatedConfirmationComponent implements OnDestroy, INewProjectDialogComponent {
  title$ = of('Create new project');
  private readonly subscription = new Subscription();
  projectId$ = this.activatedRoute.paramMap.pipe(map(params => params.get('newProjectId')));

  constructor(private dialogRef: MatDialogRef<NewProjectComponent>, private activatedRoute: ActivatedRoute, private router: Router) {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  navigateToAddMembers() {
    this.subscription.add(
      this.projectId$
        .pipe(take(1))
        .subscribe(projectId => this.router.navigate([{ outlets: { [NEW_PROJECT_DIALOG_OUTLET]: [projectId, 'members'] } }]))
    );
  }

  navigateToOverview() {
    this.subscription.add(
      this.projectId$
        .pipe(take(1))
        .subscribe(projectId => this.router.navigate([{ outlets: { [NEW_PROJECT_DIALOG_OUTLET]: [projectId, 'overview'] } }]))
    );
  }

  closeAndViewProject() {
    this.subscription.add(
      this.projectId$.pipe(take(1)).subscribe(projectId => this.router.navigate(['/project', projectId]).then(_ => this.dialogRef.close()))
    );
  }

  goBack = () => this.navigateToOverview();
}
