// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
  It applies to page with side bar.
  Example: systemParams -> systemParams.component.scss
*/
/*
  It applies to the title (header) of page content body.
*/
/*
  It describes common behavior on mouse hover.
*/
/*
  It describes light shadow for any page block.
*/
/*
  It applies to entity block like project, facility which has own title, edit button and body.
*/
/*
  It applies together with entity-card mixin, which has been described above
*/
/*
  It applies together with 'entity-card' mixin, which has been described above.
*/
/*
  It applies together with 'entity-card' mixin, which has been described above.
*/
.overview {
  background-color: #fff;
  padding: 20px;
}

.main {
  margin-top: 30px;
  display: flex;
}

.tabs {
  display: flex;
  padding: 15px 20px;
  padding-bottom: 0;
  background-color: #fff;
  margin: 0;
}

a.tab-link {
  display: inline-block;
  font-weight: normal;
  font-size: 16px;
  font-family: GothamBookMedium, sans-serif;
  text-decoration: none;
  color: #009dd9;
  margin-right: 30px;
  border: 0;
  padding-bottom: 3px;
}
a.tab-link:hover {
  color: #0066b2;
}
a.tab-link.active {
  border-bottom: 3px solid #0b2d71;
  font-weight: bold;
  color: #0b2d71;
  border-bottom-color: #0b2d71;
}

.tab {
  list-style: none;
  margin: 0;
  padding: 0;
}

.info-header {
  display: flex;
  gap: 5px;
  align-items: center;
}
.info-header .name {
  flex: 0 1 auto;
}
.info-header .status {
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-self: flex-start;
}
.info-header .status :not(app-separator) {
  display: flex;
  align-items: center;
}
.info-header .more {
  flex: 0;
  margin-left: auto;
}

.progress-number {
  font-weight: normal;
  font-size: 12px;
  font-family: GothamNarrowBook, sans-serif;
  color: #6b6d6f;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
