import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AccountInfo } from '@azure/msal-browser';
import { ApplicationInsights, IExceptionTelemetry } from '@microsoft/applicationinsights-web';
import { filter } from 'rxjs/operators';
import { UserService } from 'src/app/core/services/user.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AppInsightsMonitorService {
  appInsights: ApplicationInsights;
  loggedUser: AccountInfo;

  constructor(private router: Router, private userService: UserService) {
    this.loggedUser = userService.getCurrentUser();

    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsights.instrumentationKey,
        enableAutoRouteTracking: true, // option to log all route changes
        enableCorsCorrelation: true,
      },
    });

    this.appInsights.loadAppInsights();
    this.createRouterSubscription();
  }

  logPageView(name?: string, uri?: string) {
    this.appInsights.trackPageView({ name, uri });
  }

  logException(error: Error) {
    const exception: IExceptionTelemetry = {
      exception: error,
    };
    this.appInsights.trackException(exception);
  }

  private createRouterSubscription() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.logPageView(this.loggedUser.username, event.urlAfterRedirects);
    });
  }
}
