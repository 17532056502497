import { Component, ElementRef, EventEmitter, HostListener, Output, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';
import { GraphService } from 'src/app/core/services/graph-service';
import { debounceTime, map, shareReplay, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-azure-ad-search',
  templateUrl: './azure-ad-search.component.html',
  styleUrls: ['./azure-ad-search.component.scss'],
})
export class AzureAdSearchComponent {
  @ViewChild('searchBar') searchBar: ElementRef;
  @ViewChild('userListWrapper') userListWrapper: ElementRef;

  @Output() add = new EventEmitter<MicrosoftGraph.User>();

  private readonly DEBOUNCE_TIME_MS = 300;

  readonly searchTermSubject = new Subject<string>();
  readonly searchTerm$ = this.searchTermSubject.asObservable();
  readonly users$: Observable<MicrosoftGraph.User[]> = this.getUsers();
  public selectedUser: MicrosoftGraph.User = null;

  overlayOpen = false;

  @HostListener('document:click', ['$event.target']) onClick(target: EventTarget): void {
    if (!(this.searchBar?.nativeElement.contains(target) || this.userListWrapper?.nativeElement.contains(target))) {
      this.overlayOpen = false;
    }
  }

  constructor(private graphService: GraphService) {}

  getUsers(): Observable<MicrosoftGraph.User[]> {
    return this.searchTerm$.pipe(
      debounceTime(this.DEBOUNCE_TIME_MS),
      map(searchTerm => encodeURIComponent(searchTerm.trim())),
      map(searchTerm => searchTerm.replace(/%20/g, ' ')), // need spaces for graph api format
      switchMap(searchTerm => this.graphService.searchUsers(searchTerm)),
      shareReplay(1)
    );
  }

  clearSearchField() {
    this.selectedUser = null;
    this.searchBar.nativeElement.value = '';
    this.searchTermSubject.next('');
  }

  onKeyup() {
    this.selectedUser = null;
    this.searchTermSubject.next(this.searchBar.nativeElement.value);
    this.overlayOpen = true;
  }

  updateSearchBar(searchTerm: string): void {
    this.searchBar.nativeElement.value = searchTerm;
    this.searchTermSubject.next(searchTerm);
  }

  onSelection(user: any) {
    this.selectedUser = user.options[0]._value;
    this.updateSearchBar(user.options[0]._value.userPrincipalName);
    this.overlayOpen = false;
    this.add.emit(this.selectedUser);
    this.clearSearchField();
  }

  onFocus() {
    this.overlayOpen = true;
  }
}
