import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InputType } from '../../../shared/enums/input-type.enum';
import { Project } from '../../models/project.model';

@Component({
  selector: 'app-project-overview',
  templateUrl: './project-overview.component.html',
  styleUrls: ['./project-overview.component.scss'],
})
export class ProjectOverviewComponent {
  public InputType = InputType;

  @Input() projectNgModel: Project;
  @Output() projectNgModelChange = new EventEmitter<Project>();
}
