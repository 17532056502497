// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
  It applies to page with side bar.
  Example: systemParams -> systemParams.component.scss
*/
/*
  It applies to the title (header) of page content body.
*/
/*
  It describes common behavior on mouse hover.
*/
/*
  It describes light shadow for any page block.
*/
/*
  It applies to entity block like project, facility which has own title, edit button and body.
*/
/*
  It applies together with entity-card mixin, which has been described above
*/
/*
  It applies together with 'entity-card' mixin, which has been described above.
*/
/*
  It applies together with 'entity-card' mixin, which has been described above.
*/
:host {
  display: block;
  background-color: #fff;
  padding: 20px;
  font-weight: normal;
  font-size: 16px;
  font-family: GothamNarrowBook, sans-serif;
}

.welcome {
  font-weight: normal;
  font-size: 30px;
  font-family: GothamBold, sans-serif;
  color: #0b2d71;
  display: inline-block;
}

.header {
  margin-bottom: 28px;
}

.subheader {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
}
.subheader-main {
  flex: 1;
}
.subheader-actions {
  flex: 0 0 auto;
}
.subheader-title {
  font-weight: normal;
  font-size: 24px;
  font-family: GothamBold, sans-serif;
  margin-bottom: 8px;
  display: inline-block;
}
.subheader-description {
  color: #8c8f93;
  font-weight: normal;
  font-size: 16px;
  font-family: GothamNarrowBook, sans-serif;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
