import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { API_BASE_URL, IConfigAPIClient } from './models/autogenerated';
import { environment } from 'src/environments/environment';
import { PlaceholderComponent } from '../shared/components/placeholder/placeholder.component';
import { MatIconModule } from '@angular/material/icon';
import { PropertyBlockComponent } from './components/property-block/property-block.component';
import { StatusBadgeComponent } from './components/status-badge/status-badge.component';
import { SeparatorComponent } from './components/separator/separator.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { DeleteConfirmationDialogComponent } from './components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { EntityFilterPipe } from './pipes/entity-filter.pipe';
import { StatusFilterCountPipe } from './pipes/status-filter-count.pipe';
import { StatusFilterComponent } from './components/status-filter/status-filter.component';
import { RouterModule } from '@angular/router';
import { DialogHeaderComponent } from './components/dialog-header/dialog-header.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { ParameterValueFormat } from './pipes/parameter-value-format.pipe';
import { LoadingDirective } from './directives/loading.directive';
import { WithLoadingPipe } from './pipes/with-loading.pipe';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SpinnerComponent } from './components/spinner/spinner.component';

@NgModule({
  providers: [{ provide: API_BASE_URL, useValue: environment.apiEndpoint }, IConfigAPIClient],
  declarations: [
    PlaceholderComponent,
    PropertyBlockComponent,
    StatusBadgeComponent,
    SeparatorComponent,
    SnackbarComponent,
    DeleteConfirmationDialogComponent,
    EntityFilterPipe,
    StatusFilterCountPipe,
    StatusFilterComponent,
    DialogHeaderComponent,
    ProgressBarComponent,
    ParameterValueFormat,
    LoadingDirective,
    WithLoadingPipe,
    SpinnerComponent,
  ],
  imports: [
    RouterModule,
    CommonModule,
    MatIconModule,
    MatSnackBarModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatProgressBarModule,
    FontAwesomeModule,
    FormsModule,
    MatProgressSpinnerModule,
  ],
  exports: [
    PlaceholderComponent,
    PropertyBlockComponent,
    StatusBadgeComponent,
    SeparatorComponent,
    EntityFilterPipe,
    StatusFilterCountPipe,
    StatusFilterComponent,
    DialogHeaderComponent,
    ProgressBarComponent,
    ParameterValueFormat,
    LoadingDirective,
    WithLoadingPipe,
    SpinnerComponent,
  ],
})
export class SharedModule {}
