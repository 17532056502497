import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NEW_PROJECT_DIALOG_LINK } from 'src/app/new-project-flow/new-project-flow.constants';
import { ProjectService } from 'src/app/shared/services/project.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  constructor(private projectService: ProjectService, private router: Router, private activatedRoute: ActivatedRoute) {}

  newProjectDialogLink = NEW_PROJECT_DIALOG_LINK;

  public allProjects$ = this.projectService.allProjects$;

  ngOnInit(): void {
    this.projectService.refreshProjectList();
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { sidebar: 'show' },
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }

  public navigateToNewProjectForm() {
    this.router.navigate(this.newProjectDialogLink);
  }
}
