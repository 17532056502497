// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
  It applies to page with side bar.
  Example: systemParams -> systemParams.component.scss
*/
/*
  It applies to the title (header) of page content body.
*/
/*
  It describes common behavior on mouse hover.
*/
/*
  It describes light shadow for any page block.
*/
/*
  It applies to entity block like project, facility which has own title, edit button and body.
*/
/*
  It applies together with entity-card mixin, which has been described above
*/
/*
  It applies together with 'entity-card' mixin, which has been described above.
*/
/*
  It applies together with 'entity-card' mixin, which has been described above.
*/
:host {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px dashed #0583c0;
  height: 350px;
  background-color: #ebf9fc;
}
:host .title {
  font-weight: normal;
  font-size: 24px;
  font-family: GothamBold, sans-serif;
  color: #0066af;
}
:host .description {
  font-weight: normal;
  font-size: 16px;
  font-family: GothamNarrowBook, sans-serif;
  color: #707070;
}
:host .icon {
  font-weight: normal;
  font-size: 50px;
  font-family: GothamNarrowBook, sans-serif;
  width: auto;
  height: auto;
  color: #0066af;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
