import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-property-block',
  templateUrl: './property-block.component.html',
  styleUrls: ['./property-block.component.scss'],
})
export class PropertyBlockComponent {
  @Input() title: string;
  @Input() icon: string;
  @Input() content: string;
}
