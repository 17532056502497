// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
  It applies to page with side bar.
  Example: systemParams -> systemParams.component.scss
*/
/*
  It applies to the title (header) of page content body.
*/
/*
  It describes common behavior on mouse hover.
*/
/*
  It describes light shadow for any page block.
*/
/*
  It applies to entity block like project, facility which has own title, edit button and body.
*/
/*
  It applies together with entity-card mixin, which has been described above
*/
/*
  It applies together with 'entity-card' mixin, which has been described above.
*/
/*
  It applies together with 'entity-card' mixin, which has been described above.
*/
.scroll-container {
  padding-top: 20px;
}

.lightblue {
  color: #0066b2;
}

.grey {
  color: #6b6d6f;
}

.subheader {
  display: flex;
  gap: 10px;
  padding: 5px;
  align-items: center;
  justify-content: space-between;
}
.subheader .subtitle {
  font-weight: normal;
  font-size: 20px;
  font-family: GothamBold, sans-serif;
}
.subheader .action {
  font-weight: normal;
  font-size: 16px;
  font-family: GothamBold, sans-serif;
  line-height: normal;
}

.horizontal-container {
  display: flex;
}

.unselected {
  background-color: #f7f7f7;
}

.selected {
  background-color: #fff;
}

.vertical-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px;
}

.footer {
  box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.1607843137);
  padding: 10px;
  display: flex;
  justify-content: flex-end;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
