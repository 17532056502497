import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { NewProjectOverviewComponent } from './pages/new-project-overview/new-project-overview.component';
import { NewProjectFacilitiesComponent } from './pages/new-project-facilities/new-project-facilities.component';
import { NEW_PROJECT_DIALOG_OUTLET } from './new-project-flow.constants';
import { AddEditProjectFacilityComponent } from './pages/add-edit-project-facility/add-edit-project-facility.component';
import { NewProjectTeamMembersComponent } from './pages/new-project-team-members/new-project-team-members.component';
import { ProjectCreatedConfirmationComponent } from './pages/project-created-confirmation/project-created-confirmation.component';

const routes: Routes = [
  {
    path: '',
    component: NewProjectOverviewComponent,
    outlet: NEW_PROJECT_DIALOG_OUTLET,
    pathMatch: 'full',
  },
  {
    path: 'overview',
    component: NewProjectOverviewComponent,
    outlet: NEW_PROJECT_DIALOG_OUTLET,
  },
  {
    path: ':newProjectId/overview',
    component: NewProjectOverviewComponent,
    outlet: NEW_PROJECT_DIALOG_OUTLET,
  },
  {
    path: ':newProjectId/confirmation',
    component: ProjectCreatedConfirmationComponent,
    outlet: NEW_PROJECT_DIALOG_OUTLET,
  },
  {
    path: ':newProjectId/members',
    component: NewProjectTeamMembersComponent,
    outlet: NEW_PROJECT_DIALOG_OUTLET,
  },
  {
    path: ':newProjectId/facilities',
    component: NewProjectFacilitiesComponent,
    outlet: NEW_PROJECT_DIALOG_OUTLET,
  },
  {
    path: ':newProjectId/facility',
    component: AddEditProjectFacilityComponent,
    outlet: NEW_PROJECT_DIALOG_OUTLET,
    pathMatch: 'full',
  },
  {
    path: ':newProjectId/facility/:newFacilityId',
    component: AddEditProjectFacilityComponent,
    outlet: NEW_PROJECT_DIALOG_OUTLET,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), CommonModule],
  exports: [RouterModule],
})
export class NewProjectRoutingModule {}
