// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
  It applies to page with side bar.
  Example: systemParams -> systemParams.component.scss
*/
/*
  It applies to the title (header) of page content body.
*/
/*
  It describes common behavior on mouse hover.
*/
/*
  It describes light shadow for any page block.
*/
/*
  It applies to entity block like project, facility which has own title, edit button and body.
*/
/*
  It applies together with entity-card mixin, which has been described above
*/
/*
  It applies together with 'entity-card' mixin, which has been described above.
*/
/*
  It applies together with 'entity-card' mixin, which has been described above.
*/
:host {
  display: block;
  background: #ededee;
  padding-top: 15px;
}

.system-tabs {
  flex-grow: 1;
  overflow-x: auto;
}
.system-tabs.tabs {
  display: flex;
  margin: 0;
  gap: 10px;
  padding: 0;
  background: #ededee;
}
.system-tabs a.tab-link {
  display: inline-block;
  font-weight: normal;
  font-size: 12px;
  font-family: GothamBookMedium, sans-serif;
  text-decoration: none;
  border: 0px;
  color: #0066b2;
  padding: 8px;
  padding-bottom: 4px;
  margin: 0;
  border: 1px solid #c8c8c8;
  border-bottom-color: transparent;
  background: #f4f3f2;
}
.system-tabs a.tab-link:hover {
  color: #009dd9;
}
.system-tabs a.tab-link:active, .system-tabs a.tab-link.active {
  background: #fff none repeat scroll 0 0;
}
.system-tabs .tab {
  list-style: none;
  margin: 0;
  padding: 0;
}

.one-line {
  display: inline-block;
  white-space: nowrap;
}

.tab-label {
  font-weight: normal;
  font-size: 16px;
  font-family: GothamBookMedium, sans-serif;
  color: #0066b2;
  display: inline-block;
  white-space: nowrap;
}

.status {
  font-weight: normal;
  font-size: 12px;
  font-family: GothamNarrowBook, sans-serif;
  color: #6b6d6f;
}

.progress {
  font-weight: normal;
  font-size: 12px;
  font-family: GothamNarrowBook, sans-serif;
  color: #8c8f93;
}

.space {
  margin-right: 30px;
}

.page-button {
  background-color: #ededee;
}

.vertical {
  display: flex;
  flex-direction: column;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
