// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
  It applies to page with side bar.
  Example: systemParams -> systemParams.component.scss
*/
/*
  It applies to the title (header) of page content body.
*/
/*
  It describes common behavior on mouse hover.
*/
/*
  It describes light shadow for any page block.
*/
/*
  It applies to entity block like project, facility which has own title, edit button and body.
*/
/*
  It applies together with entity-card mixin, which has been described above
*/
/*
  It applies together with 'entity-card' mixin, which has been described above.
*/
/*
  It applies together with 'entity-card' mixin, which has been described above.
*/
:host {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.container-tabs {
  padding: 20px 20px 0px 20px;
}
.container-tabs .tabs {
  display: flex;
  margin: 0;
  padding: 0;
}
.container-tabs a.tab-link {
  display: inline-block;
  font-weight: normal;
  font-size: 16px;
  font-family: GothamBookMedium, sans-serif;
  text-decoration: none;
  color: #009dd9;
  margin-right: 50px;
  border: 0px;
  padding-bottom: 3px;
}
.container-tabs a.tab-link:hover {
  color: #0066b2;
}
.container-tabs a.tab-link.active {
  border-bottom: 3px solid #0b2d71;
  font-weight: bold;
  color: #0b2d71;
}
.container-tabs .tab {
  list-style: none;
  margin: 0;
  padding: 0;
}

.user-container {
  padding: 25px;
  font-weight: bold;
  min-height: 0;
  overflow: auto;
  text-align: center;
  margin-bottom: 10px;
}
.user-container .user-photo-placeholder {
  font-size: 30px;
  margin-right: 10px;
}
.user-container .user-image-container {
  display: flex;
  justify-content: center;
}
.user-container .user-photo {
  width: 65px;
  height: 65px;
  border-radius: 45px;
  margin-right: 10px;
}

.job-title {
  font-weight: normal;
  font-size: 16px;
  font-family: GothamNarrowBook, sans-serif;
  color: #8c8f93;
}

.assignments {
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  border-bottom: 2px #cccccc solid;
}

.assignment-section {
  text-align: center;
}

.assignment-number {
  font-weight: bold;
  font-weight: normal;
  font-size: 24px;
  font-family: GothamNarrowBook, sans-serif;
}

.assignment-description {
  font-weight: normal;
  font-size: 12px;
  font-family: GothamNarrowBook, sans-serif;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
