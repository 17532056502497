import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-system-selection-footer',
  templateUrl: './system-selection-footer.component.html',
  styleUrls: ['./system-selection-footer.component.scss'],
})
export class SystemSelectionFooterComponent {
  constructor(private activatedRoute: ActivatedRoute) {}

  projectId$ = this.activatedRoute.paramMap.pipe(map(params => params.get('projectId')));
  facilityId$ = this.activatedRoute.paramMap.pipe(map(params => params.get('facilityId')));
}
