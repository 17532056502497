// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
  It applies to page with side bar.
  Example: systemParams -> systemParams.component.scss
*/
/*
  It applies to the title (header) of page content body.
*/
/*
  It describes common behavior on mouse hover.
*/
/*
  It describes light shadow for any page block.
*/
/*
  It applies to entity block like project, facility which has own title, edit button and body.
*/
/*
  It applies together with entity-card mixin, which has been described above
*/
/*
  It applies together with 'entity-card' mixin, which has been described above.
*/
/*
  It applies together with 'entity-card' mixin, which has been described above.
*/
:host {
  margin-top: 15px;
  display: flex;
}

.user-photo-placeholder {
  width: 40px;
  height: 40px;
  font-size: 40px;
  margin-right: 10px;
}

.user-image-container {
  display: flex;
  align-items: center;
  align-self: flex-start;
}

.user-photo {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin-right: 10px;
}

.textarea {
  padding: 5px;
  border: 2px solid #c8c8c8;
  color: #212121;
  outline: none;
  border-radius: 0;
  transition: border-color 0.2s ease;
}
.textarea[disabled] {
  border-color: #c8c8c8;
  background-color: #fff;
  color: #8c8f93;
}
.textarea:focus {
  border-color: #0066b2;
}
.textarea.warning {
  border-color: #da9c36;
}
.textarea.ng-dirty.ng-invalid, .textarea.invalid {
  border-color: #e21836;
}
.textarea::placeholder {
  color: #6b6d6f;
}

.comment-wrapper {
  border: #c8c8c8 1px solid;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 15px;
  padding-bottom: 30px;
  font-weight: normal;
  font-size: 16px;
  font-family: GothamNarrowBook, sans-serif;
}

.comment-header {
  display: flex;
  justify-content: space-between;
}

.comment-info {
  display: flex;
  align-items: center;
}

.date {
  font-weight: normal;
  font-size: 16px;
  font-family: GothamNarrowBook, sans-serif;
  color: #c8c8c8;
  margin-left: 15px;
}

.edit-delete {
  display: flex;
}

.edit-comment {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.edit-comment-button {
  align-self: flex-end;
  margin-top: 15px;
}

.content {
  font-weight: normal;
  font-size: 16px;
  font-family: GothamBold, sans-serif;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
