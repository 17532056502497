// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
  It applies to page with side bar.
  Example: systemParams -> systemParams.component.scss
*/
/*
  It applies to the title (header) of page content body.
*/
/*
  It describes common behavior on mouse hover.
*/
/*
  It describes light shadow for any page block.
*/
/*
  It applies to entity block like project, facility which has own title, edit button and body.
*/
/*
  It applies together with entity-card mixin, which has been described above
*/
/*
  It applies together with 'entity-card' mixin, which has been described above.
*/
/*
  It applies together with 'entity-card' mixin, which has been described above.
*/
.table {
  width: 100%;
  border: 1px solid #ededee;
  overflow-x: auto;
}

.project-row:nth-child(odd) {
  background: #ededee;
}

.project-row:hover {
  background: #c8c8c8;
}

a.project-link {
  font-weight: normal;
  font-size: 16px;
  font-family: GothamBold, sans-serif;
  color: #0b2d71;
  text-decoration: none;
  border: 0px;
}
a.project-link:hover {
  color: #0066b2;
}

.mat-column-businessUnit,
.mat-column-region,
.mat-column-country,
.mat-column-location {
  font-weight: normal;
  font-size: 12px;
  font-family: GothamNarrowBook, sans-serif;
}

.mat-header-cell {
  font-weight: normal;
  font-size: 12px;
  font-family: GothamBookMedium, sans-serif;
  color: #212121;
}

.mat-column-name {
  min-width: 200px;
}

.mat-column-status {
  flex: 0 0 100px;
}

.mat-column-businessUnit {
  flex: 0 0 120px;
}

.mat-column-region,
.mat-column-country,
.mat-column-location {
  flex: 0 0 140px;
}

.project-grid-container {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
