import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { SystemComponent } from './pages/system.component';
import { SystemRoutingModule } from './system-routing.module';
import { SharedModule } from '../shared/shared.module';
import { CoreModule } from '../core/core.module';
import { SystemHeaderComponent } from './components/system-header/system-header.component';
import { MatIconModule } from '@angular/material/icon';
import { SystemTabStripComponent } from './components/system-tab-strip/system-tab-strip.component';

@NgModule({
  declarations: [SystemComponent, SystemHeaderComponent, SystemTabStripComponent],
  imports: [CommonModule, SharedModule, SystemRoutingModule, MatTableModule, MatSortModule, CoreModule, MatIconModule],
})
export class SystemModule {}
