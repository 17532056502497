import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { ApiException } from '../models/autogenerated';
import { AppInsightsMonitorService } from '../services/appinsights-monitor.service';
import { ToastMessagesService } from '../services/toast-messages.service';

@Injectable()
export class ApiErrorHandler implements ErrorHandler {
  constructor(private toastService: ToastMessagesService, private injector: Injector) {}

  handleError(error: Error) {
    this.injector.get<AppInsightsMonitorService>(AppInsightsMonitorService).logException(error);
    if (error instanceof ApiException) {
      if (error.status === 401) {
        this.toastService.displayError('You are not authorized to view this resource.');
      } else if (error.status === 403) {
        this.toastService.displayError('Access to this resource is forbidden.');
      } else if (error.status === 404) {
        this.toastService.displayError('Resource was not found.');
      } else {
        this.toastService.displayError('An unexpected server error occurred');
      }
    } else {
      // If not ApiException then throw error further
      throw error;
    }
  }
}
