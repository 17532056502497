import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { concatMap, throttleTime } from 'rxjs/operators';
import { FacilityViewModel, IConfigAPIClient } from 'src/app/shared/models/autogenerated';
import { FacilityService } from '../../services/facility.service';

@Component({
  selector: 'app-add-edit-facility-dialog',
  templateUrl: './add-edit-facility-dialog.component.html',
  styleUrls: ['./add-edit-facility-dialog.component.scss'],
})
export class AddEditFacilityDialogComponent implements OnInit, OnDestroy {
  private readonly subscription = new Subscription();

  public createNew: boolean;

  private throttleFacilitySubmit = new Subject<FacilityViewModel>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: FacilityViewModel,
    private dialogRef: MatDialogRef<AddEditFacilityDialogComponent>,
    private iconfigAPIClient: IConfigAPIClient,
    private facilityService: FacilityService,
    private router: Router
  ) {
    this.createNew = data.id == null;
  }
  ngOnInit(): void {
    this.subscription.add(
      this.throttleFacilitySubmit
        .pipe(
          throttleTime(500),
          concatMap(facility =>
            this.createNew ? this.iconfigAPIClient.createFacility(facility) : this.iconfigAPIClient.updateFacility(facility.id, facility)
          )
        )
        .subscribe(facility => this.submitCallback(facility))
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  Submit(facility: FacilityViewModel) {
    this.throttleFacilitySubmit.next(facility);
  }

  submitCallback(facility: FacilityViewModel) {
    this.facilityService.refreshFacility();
    this.closeDialog();
    this.router.navigate(['/project', facility.projectId, 'facility', facility.id]);
  }

  public closeDialog() {
    this.dialogRef.close();
  }
}
