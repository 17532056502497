import { Component, EventEmitter, Input, Output } from '@angular/core';
import { QuestionOption } from '../../models/question-option.model';

@Component({
  selector: 'question-multi-option',
  templateUrl: './question-multi-option.component.html',
  styleUrls: ['./question-multi-option.component.scss'],
})
export class QuestionMultiOptionComponent {
  @Input()
  public title: string;

  @Input()
  public options: QuestionOption[];

  @Output()
  public valueChanged = new EventEmitter<string>();

  public onValueChange(): void {
    const selectedOptions = this.options
      .filter(o => o.isChecked)
      .map(o => o.value)
      .reduce((acc, cur) => (acc === '' ? cur : acc + ' ; ' + cur), '');

    this.valueChanged.emit(selectedOptions);
  }
}
