import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent {
  @Input()
  progress: number;

  @HostBinding('class') get className() {
    return this.progress < 100 ? 'incomplete' : 'complete';
  }
}
