// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
  It applies to page with side bar.
  Example: systemParams -> systemParams.component.scss
*/
/*
  It applies to the title (header) of page content body.
*/
/*
  It describes common behavior on mouse hover.
*/
/*
  It describes light shadow for any page block.
*/
/*
  It applies to entity block like project, facility which has own title, edit button and body.
*/
/*
  It applies together with entity-card mixin, which has been described above
*/
/*
  It applies together with 'entity-card' mixin, which has been described above.
*/
/*
  It applies together with 'entity-card' mixin, which has been described above.
*/
:host {
  margin: 10px 0;
  display: block;
  font-weight: normal;
  font-size: 16px;
  font-family: GothamNarrowBook, sans-serif;
}

.dropdowns-container {
  overflow: auto;
  padding: 18px 16px;
  display: none;
  background-color: #f7f7f7;
  color: #0066b2;
}
.dropdowns-container.active {
  display: flex;
  gap: 10px;
}

.horizontal {
  display: flex;
  align-items: baseline;
  gap: 30px;
  justify-content: space-between;
}

.project-search {
  display: flex;
}

.input {
  padding: 5px;
  border: 2px solid #c8c8c8;
  color: #212121;
  outline: none;
  border-radius: 0;
  transition: border-color 0.2s ease;
  width: 350px;
}
.input[disabled] {
  border-color: #c8c8c8;
  background-color: #fff;
  color: #8c8f93;
}
.input:focus {
  border-color: #0066b2;
}
.input.warning {
  border-color: #da9c36;
}
.input.ng-dirty.ng-invalid, .input.invalid {
  border-color: #e21836;
}
.input::placeholder {
  color: #6b6d6f;
}

.clear {
  position: absolute;
  right: 0;
  margin: 3px;
}

.search-button {
  font-weight: normal;
  font-size: 16px;
  font-family: GothamNarrowBook, sans-serif;
  display: inline-block;
  border-width: 0;
  border-radius: 0;
  cursor: pointer;
  min-height: 30px;
  font-weight: bold;
  line-height: 22px;
  font-family: GothamBook, sans-serif;
  color: #fff;
  background-color: #0066b2;
  width: 40px;
}
.search-button.minw250 {
  min-width: 250px;
}
.search-button.minw200 {
  min-width: 200px;
}
.search-button.minw150 {
  min-width: 150px;
}
.search-button.minw100 {
  min-width: 100px;
}
.search-button.minw50 {
  min-width: 50px;
}
.search-button.minh40 {
  min-height: 40px;
}
.search-button:focus {
  outline: none;
}
.search-button mat-icon {
  vertical-align: middle;
}
.search-button:hover {
  background-color: #0b2d71;
}
.search-button:disabled {
  cursor: default;
  pointer-events: none;
  background-color: #c8c8c8;
  color: #fff;
}

.input-wrapper {
  position: relative;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
