import { Component, Input } from '@angular/core';
import { WarningViewModel } from 'src/app/shared/models/autogenerated';

@Component({
  selector: 'app-warning',
  templateUrl: './warning.component.html',
  styleUrls: ['./warning.component.scss'],
})
export class WarningComponent {
  @Input()
  warning: WarningViewModel;
}
