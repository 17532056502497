import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'StatusFilterCount',
})
export class StatusFilterCountPipe implements PipeTransform {
  transform<T extends { status?: string }>(entities: T[], args?: any): { [id: string]: number } {
    return entities?.reduce<{ [id: string]: number }>(
      (result, entity) => {
        if (result[entity.status]) {
          result[entity.status]++;
        } else {
          result[entity.status] = 1;
        }
        result['all']++;
        return result;
      },
      { all: 0 }
    );
  }
}
