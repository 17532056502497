import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map, mergeMap } from 'rxjs/operators';
import { ProjectService } from 'src/app/shared/services/project.service';

@Component({
  selector: 'app-system-selection-header',
  templateUrl: './system-selection-header.component.html',
  styleUrls: ['./system-selection-header.component.scss'],
})
export class SystemSelectionHeaderComponent {
  project$ = this.activatedRoute.paramMap.pipe(
    map(params => params.get('projectId')),
    mergeMap(projectId => this.projectService.getProject(projectId))
  );

  facilityId$ = this.activatedRoute.paramMap.pipe(map(params => params.get('facilityId')));

  constructor(private projectService: ProjectService, private activatedRoute: ActivatedRoute) {}
}
