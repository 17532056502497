import { combineLatest, concat, Observable, of, OperatorFunction } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

export interface LoadingStatus<T> {
  isLoading: boolean;
  isError?: boolean;
  value?: T;
}

/** RxJS operator to change an Observable into Observable with LoadingStatus which is supported by AppLoading directive.
 * Should not be used directly, prefer switchMapWithLoading or WithLoading pipe.
 */
export function startLoading<T>(source: Observable<T>): Observable<LoadingStatus<T>> {
  return concat(
    of<LoadingStatus<T>>({ isLoading: true }),
    source.pipe(
      map<T, LoadingStatus<T>>(value => ({ isLoading: false, value })),
      catchError(() => of<LoadingStatus<T>>({ isLoading: false, isError: true }))
    )
  );
}

/** RxJS operator which wraps an observable into a LoadingStatus<T>, and toggles the isLoading
 * state based on another observable.
 * Should be used when you want to show AppLoading directive, and the loading status is based on
 * other unrelated changes (e.g. chain of events, the start of the chain sets the isLoading subject
 * to true, and at the end of the chain, the subject is set to false)
 */
export function toggleLoading<T>(isLoadingToggle: Observable<boolean>): (source: Observable<T>) => Observable<LoadingStatus<T>> {
  return source =>
    concat(
      of<LoadingStatus<T>>({ isLoading: true }),
      combineLatest([source, isLoadingToggle]).pipe(
        map(([value, isLoading]) => ({ isLoading, value })),
        catchError(() => of<LoadingStatus<T>>({ isLoading: false, isError: true }))
      )
    );
}

/** RxJS operator which combines switchMap and startLoading operators.
 * Should be used when you want to show AppLoading directive and the observable will be changed over time (e.g. long-lived observable)
 * Example:
 * TS: const obs$ = interval(2000).pipe(switchMapWithLoading(() => of(true).pipe(delay(1000))));
 * HTML: <app-component *appLoading="obs$ | async as obs" [data]="obs"></app-component>
 */
export function switchMapWithLoading<T, K>(project: (x: T) => Observable<K>): OperatorFunction<T, LoadingStatus<K>> {
  return switchMap<T, Observable<LoadingStatus<K>>>(x => startLoading<K>(project(x)));
}
