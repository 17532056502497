import { Component, Input } from '@angular/core';
import { faCircle, IconDefinition } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'question-wrapper',
  templateUrl: './question-wrapper.component.html',
  styleUrls: ['./question-wrapper.component.scss'],
})
export class QuestionWrapperComponent {
  @Input()
  public title: string;

  faCircle: IconDefinition = faCircle;
}
