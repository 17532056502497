import { Component } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  animations: [trigger('delayedShow', [transition(':enter', [style({ opacity: 0 }), animate('300ms step-end', style({ opacity: 1 }))])])],
})
export class SpinnerComponent {}
