import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, forkJoin, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';
import { IConfigAPIClient } from 'src/app/shared/models/autogenerated';
import { TeamMember } from 'src/app/project/models/teamMember.model';
import { TeamMembersService } from '../../services/team-members.service';

@Component({
  selector: 'app-add-edit-team',
  templateUrl: './add-edit-team.component.html',
  styleUrls: ['./add-edit-team.component.scss'],
})
export class AddEditTeamComponent implements OnInit, OnDestroy {
  initialTeamMembers: TeamMember[];
  constructor(private iConfigApiClient: IConfigAPIClient, private teamMembersService: TeamMembersService) {}

  @Input() projectId: string;
  @Input() newProject: boolean;

  @Output() Submit = new EventEmitter();

  private readonly subscription = new Subscription();

  readonly updateBtnLabelSubject = new BehaviorSubject<void>(void 0);
  private readonly updateBtnLabel$ = this.updateBtnLabelSubject.asObservable();

  isUnedited$ = this.updateBtnLabel$.pipe(
    map(_ =>
      this.teamMembers.every(m => {
        let user = this.initialTeamMembers.find(x => x.azureOid === m.azureOid);
        return user && user.userProjectRoleTitle === m.userProjectRoleTitle;
      })
    )
  );
  teamMembers: TeamMember[];

  addTeamMember(user: MicrosoftGraph.User): void {
    if (this.teamMembers.find(m => m.azureOid === user.id)) {
      alert(`${user.displayName} is already a member`);
    } else {
      const member = new TeamMember({
        displayName: user.displayName,
        userPrincipalName: user.userPrincipalName,
        azureOid: user.id,
        projectId: this.projectId,
        userProjectRoleTitle: 'Member',
      });
      this.teamMembers = [...this.teamMembers, member];
      this.teamMembersService.sortTeamMembers(this.teamMembers);
    }
    this.updateBtnLabelSubject.next();
  }

  onSave() {
    const initialOids = this.initialTeamMembers.map(m => m.azureOid);

    const deleteRequests = this.initialTeamMembers
      .filter(member => !this.teamMembers.map(m => m.azureOid).includes(member.azureOid))
      .map(member => this.iConfigApiClient.deleteProjectTeamMemberAssignment(this.projectId, member.id));
    const addRequests = this.teamMembers
      .filter(member => !initialOids.includes(member.azureOid))
      .map(member => this.iConfigApiClient.createProjectTeamMemberAssignment(this.projectId, member));
    const updateRequests = this.teamMembers
      .filter(member => initialOids.includes(member.azureOid))
      .filter(
        member => this.initialTeamMembers.find(m => m.azureOid === member.azureOid).userProjectRoleTitle !== member.userProjectRoleTitle
      )
      .map(member => this.iConfigApiClient.updateProjectTeamMemberAssignment(this.projectId, member.id, member));
    const requests = [...updateRequests, ...deleteRequests, ...addRequests];
    if (requests.length === 0) {
      this.Submit.emit();
    } else {
      forkJoin(requests).subscribe(() => {
        this.Submit.emit();
      });
    }
  }

  ngOnInit(): void {
    this.subscription.add(
      this.teamMembersService.getTeamMembers(this.projectId).subscribe(members => {
        this.initialTeamMembers = members;
        this.teamMembers = members.map(x => {
          // Making copy of each member, so they are not the same as initial members
          return { ...x } as TeamMember;
        });
        this.updateBtnLabelSubject.next();
      })
    );
  }
  removeMember(member: TeamMember) {
    this.teamMembers = this.teamMembers.filter(x => x.azureOid !== member.azureOid);
    this.updateBtnLabelSubject.next();
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
