// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
  It applies to page with side bar.
  Example: systemParams -> systemParams.component.scss
*/
/*
  It applies to the title (header) of page content body.
*/
/*
  It describes common behavior on mouse hover.
*/
/*
  It describes light shadow for any page block.
*/
/*
  It applies to entity block like project, facility which has own title, edit button and body.
*/
/*
  It applies together with entity-card mixin, which has been described above
*/
/*
  It applies together with 'entity-card' mixin, which has been described above.
*/
/*
  It applies together with 'entity-card' mixin, which has been described above.
*/
:host {
  display: block;
}

.table {
  width: 100%;
  overflow-x: auto;
}

.iconf-btn-custom {
  font-weight: normal;
  font-size: 12px;
  font-family: GothamNarrowBook, sans-serif;
  min-width: 120px;
}

.name {
  font-weight: normal;
  font-size: 16px;
  font-family: GothamBold, sans-serif;
  color: #0b2d71;
}

.status {
  font-weight: normal;
  font-size: 16px;
  font-family: GothamBold, sans-serif;
}

.text {
  font-weight: normal;
  font-size: 12px;
  font-family: GothamNarrowBook, sans-serif;
}

.emphasized-text {
  font-weight: normal;
  font-size: 12px;
  font-family: GothamBold, sans-serif;
}

.header-row {
  background: #f7f7f7;
}

.header-cell {
  font-weight: normal;
  font-size: 16px;
  font-family: GothamNarrowBook, sans-serif;
  color: #212121;
}

.select-all {
  cursor: pointer;
}
.select-all:hover {
  opacity: 0.8;
}

.mat-column-name {
  min-width: 150px;
}

.mat-column-selected {
  flex: 0 0 100px;
}

.mat-column-status {
  flex: 0 0 100px;
}

.mat-column-progress {
  flex: 0 0 120px;
}

.mat-column-dates {
  flex: 0 0 160px;
}

.mat-column-action {
  flex: 0 0 130px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
