// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.system-name {
  margin: 20px;
}

.wrapper {
  display: flex;
}

.main {
  flex: 1 0;
  display: flex;
  flex-direction: column;
}
.main :last-child {
  display: none;
}

.sidebar {
  flex: 0 0 400px;
  padding: 20px;
}

.spacer {
  flex: 0 0 1px;
  border-right: 1px;
  border-right-style: solid;
  border-color: #cccccc;
}

.sidebar-content {
  position: sticky;
  top: 0;
}

.footer-recommendation {
  display: flex;
  justify-content: center;
  padding: 20px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
