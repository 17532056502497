import { Component, Input } from '@angular/core';

@Component({
  selector: 'question-text',
  templateUrl: './question-text.component.html',
  styleUrls: ['./question-text.component.scss'],
})
export class QuestionTextComponent {
  @Input()
  public title: string;

  public value: string;
  public isEmpty: boolean;

  public validateAnswer(): void {
    this.isEmpty = this.value?.length === 0;
  }
}
