// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
  It applies to page with side bar.
  Example: systemParams -> systemParams.component.scss
*/
/*
  It applies to the title (header) of page content body.
*/
/*
  It describes common behavior on mouse hover.
*/
/*
  It describes light shadow for any page block.
*/
/*
  It applies to entity block like project, facility which has own title, edit button and body.
*/
/*
  It applies together with entity-card mixin, which has been described above
*/
/*
  It applies together with 'entity-card' mixin, which has been described above.
*/
/*
  It applies together with 'entity-card' mixin, which has been described above.
*/
.ic-question-text .ic-question-text__answer-input {
  height: 35px;
  font-weight: normal;
  font-size: 16px;
  font-family: GothamNarrowBook, sans-serif;
  padding: 5px;
  border: 2px solid #009dd9;
  color: #212121;
  outline: none;
  min-width: 250px;
  width: 80%;
}
.ic-question-text .ic-question-text__answer-input[disabled] {
  border-color: #c8c8c8;
  background-color: #fff;
}
.ic-question-text .ic-question-text__answer-input.out-of-bounds {
  border-color: #da9c36;
}
.ic-question-text .ic-question-text__answer-input.invalid {
  border-color: #e21836;
}
.ic-question-text .ic-question-text__answer-input::placeholder {
  color: #c8c8c8;
}
.ic-question-text .ic-question-number_error {
  font-weight: normal;
  font-size: 16px;
  font-family: GothamNarrowBook, sans-serif;
  margin-left: 2%;
  color: #e21836;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
