// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: grid;
  grid-template-areas: "header header  header" ".      main    ." "footer footer  footer";
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 1fr auto 1fr;
  height: 100vh;
  background-color: #f4f3f2;
}

.main-container {
  display: flex;
  gap: 20px;
  grid-area: main;
  overflow: hidden;
  max-width: 100vw;
  width: 1400px;
  margin: initial;
}

.main-content {
  flex: 1 0;
  overflow: auto;
  margin: 20px 0;
}

.sidebar-content {
  flex: 0 0 350px;
  background-color: #fff;
  position: sticky;
  top: 0;
}

app-footer {
  grid-area: footer;
}

app-header {
  grid-area: header;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
