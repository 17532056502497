import { Pipe, PipeTransform } from '@angular/core';
import { TeamMember } from '../models/teamMember.model';

@Pipe({
  name: 'FilterMembers',
})
export class FilterMembersPipe implements PipeTransform {
  transform(value: TeamMember[], searchText: string): TeamMember[] {
    return searchText
      ? value.filter(
          m =>
            m.displayName.toLocaleLowerCase().startsWith(searchText.toLocaleLowerCase()) ||
            m.userPrincipalName.toLocaleLowerCase().startsWith(searchText.toLocaleLowerCase())
        )
      : value;
  }
}
