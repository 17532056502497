import {
  ComponentFactory,
  ComponentFactoryResolver,
  Directive,
  EmbeddedViewRef,
  Input,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

// import { ErrorComponent } from '../components/error/error.component';
import { SpinnerComponent } from '../components/spinner/spinner.component';
import { LoadingStatus } from '../utils/loading';

export interface LoadingContext<T> {
  $implicit?: T;
  appLoading?: T;
}

/** Directive to show loader/error based on the Observable status. The Observable should be of LoadingStatus<T> type.
 * For short-lived observables use withLoading pipe, for long-lived - switchMapWithLoading RxJS operator.
 * Example for short-lived:
 * TS: const obs$ = interval(2000).pipe(switchMapWithLoading(() => of(true).pipe(delay(1000))));
 * HTML: <app-component *appLoading="obs$ | async as obs" [data]="obs"></app-component>
 * Example for long-lived:
 * TS: const obs$ = interval(2000).pipe(switchMapWithLoading(() => of(true).pipe(delay(1000))));
 * HTML: <app-component *appLoading="obs$ | async as obs" [data]="obs"></app-component>
 */
@Directive({
  selector: '[appLoading]',
})
export class LoadingDirective<T> {
  @Input()
  set appLoading(result: LoadingStatus<T>) {
    this.showContent(result);
  }

  private readonly loadingFactory: ComponentFactory<SpinnerComponent>;
  private embeddedViewRef: EmbeddedViewRef<LoadingContext<T>> | null;

  constructor(
    private templateRef: TemplateRef<LoadingContext<T>>,
    private vcRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {
    this.loadingFactory = this.componentFactoryResolver.resolveComponentFactory(SpinnerComponent);
  }

  private showContent(result: LoadingStatus<T>): void {
    if (!result) {
      // ignore nulls from async pipe
      return;
    }

    this.vcRef.clear();
    if (result.value) {
      const context: LoadingContext<T> = {};
      context.$implicit = context.appLoading = result.value;
      this.embeddedViewRef = this.vcRef.createEmbeddedView<LoadingContext<T>>(this.templateRef, context);
    }

    if (result.isLoading) {
      this.vcRef.createComponent(this.loadingFactory);
    }
  }
}
