import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ProjectViewModel, IConfigAPIClient } from 'src/app/shared/models/autogenerated';
import { ProjectService } from 'src/app/shared/services/project.service';

@Component({
  selector: 'app-add-edit-project-dialog',
  templateUrl: './add-edit-project-dialog.component.html',
  styleUrls: ['./add-edit-project-dialog.component.scss'],
})
export class AddEditProjectDialogComponent implements OnDestroy {
  private readonly subscription = new Subscription();

  public createNew: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ProjectViewModel,
    private dialogRef: MatDialogRef<AddEditProjectDialogComponent>,
    private iconfigAPIClient: IConfigAPIClient,
    private projectService: ProjectService
  ) {
    this.createNew = data.id == null;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  Submit(project: ProjectViewModel) {
    if (this.createNew) {
      this.subscription.add(this.iconfigAPIClient.createProject(project).subscribe(() => this.submitCallback()));
    } else {
      this.subscription.add(this.iconfigAPIClient.updateProject(project.id, project).subscribe(() => this.submitCallback()));
    }
  }
  submitCallback() {
    this.projectService.refreshProjectList();
    this.closeDialog();
  }
  public closeDialog() {
    this.dialogRef.close();
  }
}
