import { Component, Input } from '@angular/core';
import { ParameterViewModel } from 'src/app/shared/models/autogenerated';

@Component({
  selector: 'parameter-list',
  templateUrl: './parameter-list.component.html',
  styleUrls: ['./parameter-list.component.scss'],
})
export class ParameterListComponent {
  @Input()
  public parameters: ParameterViewModel[];
}
