// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
  It applies to page with side bar.
  Example: systemParams -> systemParams.component.scss
*/
/*
  It applies to the title (header) of page content body.
*/
/*
  It describes common behavior on mouse hover.
*/
/*
  It describes light shadow for any page block.
*/
/*
  It applies to entity block like project, facility which has own title, edit button and body.
*/
/*
  It applies together with entity-card mixin, which has been described above
*/
/*
  It applies together with 'entity-card' mixin, which has been described above.
*/
/*
  It applies together with 'entity-card' mixin, which has been described above.
*/
:host {
  display: block;
  padding: 20px;
}

.pfd-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 1em;
}

app-warning {
  margin-bottom: 10px;
}

.pfd-header,
.note-header {
  font-weight: normal;
  font-size: 24px;
  font-family: GothamBold, sans-serif;
  display: flex;
  justify-content: space-between;
  color: #212121;
  font-weight: normal;
  margin-top: 15px;
  margin-bottom: 15px;
}

.note-subtitle {
  font-weight: normal;
  font-size: 16px;
  font-family: GothamBold, sans-serif;
  color: #0b2d71;
  font-weight: normal;
  margin-top: 12px;
  margin-bottom: 12px;
}

.note-content {
  font-weight: normal;
  font-size: 16px;
  font-family: GothamNarrowBook, sans-serif;
  font-weight: normal;
  margin-bottom: 10px;
}

.horizontal {
  display: flex;
  justify-content: space-between;
}

.buttons-align {
  display: flex;
  gap: 20px;
}

.download-button {
  font-weight: normal;
  font-size: 16px;
  font-family: GothamNarrowBook, sans-serif;
  display: inline-block;
  border-width: 0;
  border-radius: 0;
  cursor: pointer;
  min-height: 30px;
  font-weight: bold;
  line-height: 22px;
  font-family: GothamBook, sans-serif;
  color: #fff;
  background-color: #0066b2;
  padding: 8px 16px;
}
.download-button.minw250 {
  min-width: 250px;
}
.download-button.minw200 {
  min-width: 200px;
}
.download-button.minw150 {
  min-width: 150px;
}
.download-button.minw100 {
  min-width: 100px;
}
.download-button.minw50 {
  min-width: 50px;
}
.download-button.minh40 {
  min-height: 40px;
}
.download-button:focus {
  outline: none;
}
.download-button mat-icon {
  vertical-align: middle;
}
.download-button:hover {
  background-color: #0b2d71;
}
.download-button:disabled {
  cursor: default;
  pointer-events: none;
  background-color: #c8c8c8;
  color: #fff;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
