import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { IConfigAPIClient, SystemViewModel } from '../models/autogenerated';

@Injectable({
  providedIn: 'root',
})
export class SystemService {
  constructor(private iConfigAPIClient: IConfigAPIClient) {}

  public systemsRefreshSubject = new BehaviorSubject<void>(void 0);
  public systemsRefresh$ = this.systemsRefreshSubject.asObservable();

  public refreshSystems(): void {
    this.systemsRefreshSubject.next();
  }

  public getSystem(systemId$: Observable<string>): Observable<SystemViewModel> {
    return combineLatest([systemId$, this.systemsRefresh$]).pipe(
      switchMap(([systemId, _]) => this.iConfigAPIClient.getSystemById(systemId))
    );
  }

  public getSystems(systemIds$: Observable<string[]>): Observable<SystemViewModel[]> {
    return combineLatest([systemIds$, this.systemsRefresh$]).pipe(
      switchMap(([systemIds, _]) => this.iConfigAPIClient.getSystemsByIds(systemIds))
    );
  }
}
