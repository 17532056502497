import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { of, Subject, Subscription } from 'rxjs';
import { concatMap, map, switchMap, tap, throttleTime } from 'rxjs/operators';
import { ProjectViewModel, IConfigAPIClient } from 'src/app/shared/models/autogenerated';
import { ProjectService } from 'src/app/shared/services/project.service';
import { INewProjectDialogComponent } from '../../models/new-project-dialog-component.model';
import { NEW_PROJECT_DIALOG_OUTLET } from '../../new-project-flow.constants';

@Component({
  selector: 'app-new-project-overview',
  templateUrl: './new-project-overview.component.html',
  styleUrls: ['./new-project-overview.component.scss'],
})
export class NewProjectOverviewComponent implements OnInit, OnDestroy, INewProjectDialogComponent {
  private readonly subscription = new Subscription();
  private throttleProjectSubmit = new Subject<ProjectViewModel>();

  constructor(
    private dialogRef: MatDialogRef<NewProjectOverviewComponent>,
    private iconfigAPIClient: IConfigAPIClient,
    private router: Router,
    private projectService: ProjectService,
    private activatedRoute: ActivatedRoute
  ) {}
  ngOnInit(): void {
    this.subscription.add(
      this.throttleProjectSubmit
        .pipe(
          throttleTime(500),
          concatMap(project =>
            this.createNew ? this.iconfigAPIClient.createProject(project) : this.iconfigAPIClient.updateProject(project.id, project)
          )
        )
        .subscribe(response => this.submitCallback(response))
    );
  }

  projectId$ = this.activatedRoute.paramMap.pipe(map(params => params.get('newProjectId')));
  project$ = this.projectId$.pipe(
    tap(id => (this.createNew = id == null)),
    switchMap(id => (id == null ? of({}) : this.projectService.getProject(id)))
  );
  public createNew = false;
  title$ = this.projectId$.pipe(map(projectId => (projectId == null ? 'Create new project' : 'Edit new project')));

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  Cancel() {
    this.dialogRef.close();
  }

  Submit(project: ProjectViewModel) {
    this.throttleProjectSubmit.next(project);
  }

  submitCallback(response) {
    this.projectService.refreshProjectList();
    this.router
      .navigate([{ outlets: { [NEW_PROJECT_DIALOG_OUTLET]: [response.id, 'overview'] } }], { replaceUrl: true })
      .then(() =>
        this.router.navigate([{ outlets: { [NEW_PROJECT_DIALOG_OUTLET]: [response.id, this.createNew ? 'confirmation' : 'members'] } }])
      );
  }

  goBack = () => this.Cancel();
}
