import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-status-filter',
  templateUrl: './status-filter.component.html',
  styleUrls: ['./status-filter.component.scss'],
})
export class StatusFilterComponent {
  constructor(private activatedRoute: ActivatedRoute) {}
  @Input()
  public filterTabsCount: { [id: string]: number };
  @Input()
  public filterTabs: string[];

  public activeTab$ = this.activatedRoute.queryParamMap.pipe(map(params => params.get('status') ?? 'all'));
}
