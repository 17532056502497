import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { trigger, style, animate, transition } from '@angular/animations';
import { AppInsightsMonitorService } from './shared/services/appinsights-monitor.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('fadeSlideInOut', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateX(100%)' }),
        animate('200ms', style({ opacity: 1, transform: 'translateX(0)' })),
      ]),
      transition(':leave', [animate('200ms', style({ opacity: 0, transform: 'translateX(100%)' }))]),
    ]),
  ],
})
export class AppComponent {
  title = 'IConfig';
  name = '';
  loggedin: boolean;

  toggleSidebar: Observable<boolean>;

  constructor(private route: ActivatedRoute, appinsights: AppInsightsMonitorService) {
    this.toggleSidebar = this.route.queryParamMap.pipe(map(params => params.has('sidebar')));
  }
}
