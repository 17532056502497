// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
  It applies to page with side bar.
  Example: systemParams -> systemParams.component.scss
*/
/*
  It applies to the title (header) of page content body.
*/
/*
  It describes common behavior on mouse hover.
*/
/*
  It describes light shadow for any page block.
*/
/*
  It applies to entity block like project, facility which has own title, edit button and body.
*/
/*
  It applies together with entity-card mixin, which has been described above
*/
/*
  It applies together with 'entity-card' mixin, which has been described above.
*/
/*
  It applies together with 'entity-card' mixin, which has been described above.
*/
:host {
  display: block;
  flex: 1;
}

.form-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.label {
  font-weight: normal;
  font-size: 16px;
  font-family: GothamNarrowBook, sans-serif;
  text-align: left;
  margin: 5px 0px;
}

.container-horizontal {
  display: flex;
  gap: 10px;
}

.container-vertical {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.input {
  padding: 5px;
  border: 2px solid #c8c8c8;
  color: #212121;
  outline: none;
  border-radius: 0;
  transition: border-color 0.2s ease;
}
.input[disabled] {
  border-color: #c8c8c8;
  background-color: #fff;
  color: #8c8f93;
}
.input:focus {
  border-color: #0066b2;
}
.input.warning {
  border-color: #da9c36;
}
.input.ng-dirty.ng-invalid, .input.invalid {
  border-color: #e21836;
}
.input::placeholder {
  color: #6b6d6f;
}

.textarea {
  padding: 5px;
  border: 2px solid #c8c8c8;
  color: #212121;
  outline: none;
  border-radius: 0;
  transition: border-color 0.2s ease;
}
.textarea[disabled] {
  border-color: #c8c8c8;
  background-color: #fff;
  color: #8c8f93;
}
.textarea:focus {
  border-color: #0066b2;
}
.textarea.warning {
  border-color: #da9c36;
}
.textarea.ng-dirty.ng-invalid, .textarea.invalid {
  border-color: #e21836;
}
.textarea::placeholder {
  color: #6b6d6f;
}

.actions {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  padding-top: 10px;
  margin-top: auto;
}

.project-type-box {
  flex: 1 1 0px;
  outline: 1px solid #009dd9;
  transition: outline 0.1s linear;
  margin: 5px 0px 15px 0px;
  padding: 15px;
  background-color: transparent;
  border: 2px solid transparent;
}
.project-type-box:hover {
  background-color: #ededee;
}
.project-type-box.project-type-box-active {
  outline: 2px solid #0066b2;
  background-color: #f4f3f2;
}
.project-type-box span {
  color: #8c8f93;
  text-align: left;
  font-weight: normal;
  font-size: 16px;
  font-family: GothamNarrowBook, sans-serif;
  display: inline-block;
  line-height: 100%;
}
.project-type-box h3 {
  font-weight: normal;
  font-size: 16px;
  font-family: GothamBookMedium, sans-serif;
  color: #0b2d71;
  text-align: left;
  margin: 0;
  line-height: 100%;
}
.project-type-box .icon-container {
  height: 100%;
  padding: 8px 8px;
  display: flex;
  font-weight: normal;
  font-size: 24px;
  font-family: GothamNarrowBook, sans-serif;
  align-items: center;
}
.project-type-box mat-icon {
  color: #0066b2;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
