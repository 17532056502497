import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecommendationComponent } from './pages/recommendation/recommendation.component';
import { RecommendationRoutingModule } from './recommendation-routing.module';
import { PfdOutlineComponent } from './components/pfd-outline/pfd-outline.component';
import { ParameterListComponent } from './components/parameter-list/parameter-list.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { WarningComponent } from './components/warning/warning.component';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [RecommendationComponent, PfdOutlineComponent, ParameterListComponent, WarningComponent],
  imports: [CommonModule, SharedModule, RecommendationRoutingModule, FontAwesomeModule, MatIconModule],
  exports: [PfdOutlineComponent, WarningComponent],
})
export class RecommendationModule {}
