import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../core/core.module';
import { RouterModule, Routes } from '@angular/router';
import { SystemComponent } from './pages/system.component';

const routes: Routes = [
  {
    path: ':systemId',
    component: SystemComponent,
    children: [
      {
        path: 'questionnaire',
        loadChildren: () => import('src/app/questionnaire/questionnaire.module').then(m => m.QuestionnaireModule),
      },
      {
        path: 'recommendation',
        loadChildren: () => import('src/app/recommendation/recommendation.module').then(m => m.RecommendationModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), CommonModule, CoreModule],
  exports: [RouterModule],
})
export class SystemRoutingModule {}
