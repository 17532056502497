// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  width: 200px;
}

mat-progress-bar {
  height: 7px;
  border-radius: 4px;
  border: 1px solid #ededee;
}

::ng-deep .complete .mat-progress-bar-fill::after {
  background-color: #2fd135;
}

::ng-deep .incomplete .mat-progress-bar-fill::after {
  background-color: #009dd9;
}

::ng-deep .mat-progress-bar-buffer {
  background: #fff;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
