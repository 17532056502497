// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `mat-dialog-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.dialog-container {
  display: flex;
  gap: 10px;
  flex: 1;
  min-height: 0;
}

.content {
  flex: 3 0 0;
}

.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
