import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuestionnaireComponent } from './pages/questionnaire/questionnaire.component';
import { QuestionnaireRoutingModule } from './questionnaire-routing.module';
import { QuestionYesNoButtonsComponent } from './components/question-yesno-buttons/question-yesno-buttons.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { QuestionOptionsComponent } from './components/question-options/question-options.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QuestionTextComponent } from './components/question-text/question-text.component';
import { QuestionNumberComponent } from './components/question-number/question-number.component';
import { QuestionMultiOptionComponent } from './components/question-multi-option/question-multi-option.component';
import { QuestionWrapperComponent } from './components/question-wrapper/question-wrapper.component';
import { QuestionnaireSampleComponent } from './components/questionnaire-sample/questionnaire-sample.component';
import { QuestionComponent } from './components/question/question.component';
import { StringToBooleanPipe } from './pipes/string-to-boolean/string-to-boolean.pipe';
import { StringToOptionsPipe } from './pipes/string-to-options/string-to-options.pipe';
import { RecommendationSidebarComponent } from './components/recommendation-sidebar/recommendation-sidebar.component';
import { RecommendationModule } from '../recommendation/recommendation.module';
import { QuestionCommentComponent } from './components/question-comment/question-comment.component';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '../shared/shared.module';
import { QuestionnaireFooterComponent } from './components/questionnaire-footer/questionnaire-footer.component';
import { CoreModule } from '../core/core.module';

@NgModule({
  declarations: [
    QuestionnaireComponent,
    QuestionYesNoButtonsComponent,
    QuestionOptionsComponent,
    QuestionTextComponent,
    QuestionNumberComponent,
    QuestionMultiOptionComponent,
    QuestionWrapperComponent,
    QuestionnaireSampleComponent,
    QuestionComponent,
    StringToBooleanPipe,
    StringToOptionsPipe,
    RecommendationSidebarComponent,
    QuestionCommentComponent,
    QuestionnaireFooterComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    QuestionnaireRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    RecommendationModule,
    MatIconModule,
    CoreModule,
  ],
  providers: [],
})
export class QuestionnaireModule {}
