// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
  It applies to page with side bar.
  Example: systemParams -> systemParams.component.scss
*/
/*
  It applies to the title (header) of page content body.
*/
/*
  It describes common behavior on mouse hover.
*/
/*
  It describes light shadow for any page block.
*/
/*
  It applies to entity block like project, facility which has own title, edit button and body.
*/
/*
  It applies together with entity-card mixin, which has been described above
*/
/*
  It applies together with 'entity-card' mixin, which has been described above.
*/
/*
  It applies together with 'entity-card' mixin, which has been described above.
*/
:host {
  display: block;
  background: #fff;
  padding-top: 0;
}

.breadcrumb {
  margin: 0 20px;
}

.system-nav {
  margin: 20px 20px 20px;
  display: flex;
  gap: 10px;
}
.system-nav a {
  font-weight: 900;
  font-weight: normal;
  font-size: 16px;
  font-family: GothamNarrowBook, sans-serif;
  display: inline-block;
  text-decoration: none;
  border: 0px;
}
.system-nav a:link,
.system-nav a:visited {
  color: #0066b2;
}
.system-nav a:hover {
  color: #009dd9;
}
.system-nav a:active,
.system-nav a.active-nav {
  color: #0b2d71;
  text-decoration-line: underline;
  text-decoration-thickness: 2px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
