import { Component, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { ActivatedRoute, ActivationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NEW_PROJECT_DIALOG_OUTLET } from '../../new-project-flow.constants';
import { NewProjectComponent } from '../../pages/new-project/new-project.component';

@Component({
  selector: 'app-new-project-dialog-entry',
  template: '',
  styles: [':host{ display: none }'],
})
export class NewProjectDialogEntryComponent implements OnDestroy {
  private readonly subscription = new Subscription();
  private dialogRef: MatDialogRef<NewProjectComponent, any>;

  constructor(public dialog: MatDialog, private router: Router, private route: ActivatedRoute) {
    this.subscription.add(
      this.router.events.subscribe(event => {
        if (event instanceof ActivationEnd) {
          const dialogIsOpen = this.dialogRef?.getState() === MatDialogState.OPEN;
          if (event.snapshot.outlet === NEW_PROJECT_DIALOG_OUTLET && !dialogIsOpen) {
            this.openDialog();
          }
          const noOutlet = this.route.children.filter(child => child.outlet === NEW_PROJECT_DIALOG_OUTLET);
          if (noOutlet.length <= 0 && dialogIsOpen) {
            this.dialogRef.close();
          }
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  openDialog(): void {
    this.dialogRef = this.dialog.open(NewProjectComponent, {
      panelClass: 'new-project-dialog-config',
      closeOnNavigation: false,
      disableClose: true,
      maxWidth: null, // by default the library sets it to 80vw
    });
    this.dialogRef.afterClosed().subscribe(result => {
      this.router.navigate([{ outlets: { [NEW_PROJECT_DIALOG_OUTLET]: null } }], { relativeTo: this.route });
      this.dialogRef.componentInstance.closeWindow();
    });
  }
}
