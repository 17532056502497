import { YesNoOption } from '../models/yesno-option.model';

export const yesNoOptions: YesNoOption[] = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];
