import { Component, Inject } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
//import { MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA } from '@angular/material/legacy-snack-bar';
import { SnackbarData } from '../../models/snackbar-data.model';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
})
export class SnackbarComponent {
  constructor(@Inject(MatSnackBar) public data: SnackbarData) {}
}
