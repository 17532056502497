import { Pipe, PipeTransform } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FilterModel } from '../models/filter.model';

@Pipe({
  name: 'EntityFilter',
})
export class EntityFilterPipe implements PipeTransform {
  constructor(private activatedRoute: ActivatedRoute) {}
  transform<T extends { name?: string }>(entities: T[], filters: FilterModel[]): Observable<T[]> {
    const entityProps = filters.map(x => x.filterProperty);
    const prefixCompare = (s1: string, s2: string) => s1.toLowerCase().startsWith(s2.toLowerCase());

    return this.activatedRoute.queryParamMap.pipe(
      map(params =>
        params.keys.reduce<T[]>(
          (accEntities, key) =>
            entityProps.includes(key) && params.get(key) !== 'all'
              ? accEntities.filter(p => (key === 'name' ? prefixCompare(p.name, params.get(key)) : p[key] === params.get(key)))
              : accEntities,
          entities
        )
      )
    );
  }
}
