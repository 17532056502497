import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { QuestionOption } from '../../models/question-option.model';

@Component({
  selector: 'question-options',
  templateUrl: './question-options.component.html',
  styleUrls: ['./question-options.component.scss'],
})
export class QuestionOptionsComponent implements OnInit {
  @Input()
  options: QuestionOption[];

  @Input()
  public title: string;

  public value: string;

  @Output()
  public valueChanged = new EventEmitter<string>();

  ngOnInit() {
    const selectedOption = this.options?.filter(o => o.isChecked === true);
    this.value = selectedOption?.length > 0 ? selectedOption[0].value : '';
  }

  public onValueChange(): void {
    this.valueChanged.emit(this.value);
  }
}
