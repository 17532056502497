import { Component, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, Subscription } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { FacilityViewModel } from 'src/app/shared/models/autogenerated';
import { FacilityService } from '../../../facility/services/facility.service';
import { INewProjectDialogComponent } from '../../models/new-project-dialog-component.model';
import { NEW_PROJECT_DIALOG_OUTLET } from '../../new-project-flow.constants';
import { NewProjectComponent } from '../new-project/new-project.component';

@Component({
  selector: 'app-new-project-facilities',
  templateUrl: './new-project-facilities.component.html',
  styleUrls: ['./new-project-facilities.component.scss'],
})
export class NewProjectFacilitiesComponent implements INewProjectDialogComponent, OnDestroy {
  title$ = of('Add facilities');
  private readonly subscription = new Subscription();
  constructor(
    private dialogRef: MatDialogRef<NewProjectComponent>,
    private activatedRoute: ActivatedRoute,
    private facilityService: FacilityService,
    private router: Router
  ) {}

  projectId$ = this.activatedRoute.paramMap.pipe(map(params => params.get('newProjectId')));

  public facilities$: Observable<FacilityViewModel[]> = this.getFacilities();

  getFacilities(): Observable<FacilityViewModel[]> {
    return this.projectId$.pipe(switchMap(projectId => this.facilityService.getFacilitiesInProject(projectId)));
  }

  deleteFacility(id) {
    this.subscription.add(
      this.facilityService.deleteFacility(id).subscribe(_ => {
        this.facilityService.refreshFacility();
      })
    );
  }

  addFacility(): void {
    this.subscription.add(
      this.projectId$
        .pipe(take(1))
        .subscribe(projectId => this.router.navigate([{ outlets: { [NEW_PROJECT_DIALOG_OUTLET]: [projectId, 'facility'] } }]))
    );
  }

  editFacility(facilityId: number): void {
    this.subscription.add(
      this.projectId$
        .pipe(take(1))
        .subscribe(projectId => this.router.navigate([{ outlets: { [NEW_PROJECT_DIALOG_OUTLET]: [projectId, 'facility', facilityId] } }]))
    );
  }

  public navigateToMembers() {
    this.subscription.add(
      this.projectId$
        .pipe(take(1))
        .subscribe(projectId => this.router.navigate([{ outlets: { [NEW_PROJECT_DIALOG_OUTLET]: [projectId, 'members'] } }]))
    );
  }

  public navigateToProjectAndClose() {
    this.subscription.add(
      this.projectId$.pipe(take(1)).subscribe(projectId => this.router.navigate(['/project', projectId]).then(_ => this.dialogRef.close()))
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  goBack = () => this.navigateToMembers();
}
