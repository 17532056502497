import { Component, OnDestroy } from '@angular/core';
import { UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { SidebarItem } from '../../models/sidebar-item.model';

@Component({
  selector: 'app-new-project-sidebar',
  templateUrl: './new-project-sidebar.component.html',
  styleUrls: ['./new-project-sidebar.component.scss'],
})
export class NewProjectSidebarComponent implements OnDestroy {
  private readonly subscription = new Subscription();
  navigationItems: SidebarItem[] = [
    {
      text: '1. Create new project',
      routes: ['overview', 'confirmation'],
      isActive: true,
    },
    {
      text: '2. Add project members',
      routes: ['members'],
      isActive: false,
    },
    {
      text: '3. Add facilities',
      routes: ['facilities', 'facility'],
      isActive: false,
    },
  ];
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  setActiveStatus(url$: Observable<UrlSegment[]>) {
    // Check current route and set active item
    this.subscription.add(
      url$.pipe(map(url => url.map(u => u.path))).subscribe(paths => {
        this.navigationItems = this.navigationItems.map(i => ({ ...i, isActive: paths.some(p => i.routes.includes(p)) }));
      })
    );
  }
}
