import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'question-number',
  templateUrl: './question-number.component.html',
  styleUrls: ['./question-number.component.scss'],
})
export class QuestionNumberComponent implements OnInit {
  @Input()
  minValue: number;

  @Input()
  maxValue: number;

  @Input()
  public title: string;

  @Input()
  public value: number;

  @Input()
  public isEmpty: boolean;

  @Output()
  public valueChanged = new EventEmitter<string>();

  public isOutOfBounds: boolean;

  ngOnInit() {
    this.validateIfOutOfBounds();
  }

  public getPlaceholder(): string {
    return this.hasRange() ? `${this.minValue}-${this.maxValue}` : 'enter value';
  }

  public validateAnswer(): void {
    this.isEmpty = this.value == null;
    this.validateIfOutOfBounds();
  }

  public onValueChange(): void {
    this.valueChanged.emit(this.value?.toString());
  }

  private hasRange = () => this.minValue != null && this.maxValue != null;

  private validateIfOutOfBounds() {
    this.isOutOfBounds = this.value != null && this.hasRange() && (this.value < this.minValue || this.value > this.maxValue);
  }
}
