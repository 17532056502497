import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PfdViewModel } from 'src/app/shared/models/autogenerated';
import { faDownload, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-pfd-outline',
  templateUrl: './pfd-outline.component.html',
  styleUrls: ['./pfd-outline.component.scss'],
})
export class PfdOutlineComponent {
  @Input()
  public pfd: PfdViewModel;
  public faExternalLinkAlt = faExternalLinkAlt;
  public faDownload = faDownload;

  @Output()
  openPfd = new EventEmitter<string>();

  @Output()
  downloadPfd = new EventEmitter<string>();
}
