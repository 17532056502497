// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex: 1;
  background-color: white;
  padding: 20px 50px;
  box-shadow: 0 -8px 8px -8px rgba(0, 0, 0, 0.2);
  justify-content: space-between;
}

.iconf-btn-blank {
  color: #8c8f93;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
