import { EventEmitter, Input, Output } from '@angular/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FacilityViewModel } from 'src/app/shared/models/autogenerated';

@Component({
  selector: 'app-add-edit-facility',
  templateUrl: './add-edit-facility.component.html',
  styleUrls: ['./add-edit-facility.component.scss'],
})
export class AddEditFacilityComponent implements OnInit, OnDestroy {
  private readonly subscription = new Subscription();

  facilityForm = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required]),
    description: new UntypedFormControl(''),
    assetType: new UntypedFormControl('', [Validators.required]),
    facilityType: new UntypedFormControl('', [Validators.required]),
  });

  @Input()
  public facility: FacilityViewModel;
  @Input()
  public submitText: string;

  @Output()
  public facilitySubmitted = new EventEmitter<FacilityViewModel>();
  @Output()
  public cancel = new EventEmitter();

  // TODO: should be changed with API change
  assetTypeOptions: string[] = ['Offshore'];
  facilityTypeOptions: string[] = ['Topsides'];

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.facilityForm.patchValue(this.facility);
  }

  public Submit() {
    if (this.facilityForm.invalid) {
      return;
    }

    this.facility = { ...this.facility, ...this.facilityForm.value };
    this.facilitySubmitted.emit(this.facility);
  }
}
