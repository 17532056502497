import { Pipe, PipeTransform } from '@angular/core';
import { QuestionOption } from '../../models/question-option.model';

@Pipe({
  name: 'StringToOptions',
})
export class StringToOptionsPipe implements PipeTransform {
  transform(value: string, selectedvalue?: string, args?: any): QuestionOption[] {
    const options = value
      ? value
          .split(';')
          .filter(option => option !== '')
          .map(option => ({ value: option.trim(), title: option.trim(), isChecked: false }))
      : [];

    if (selectedvalue) {
      const selectedOptions = selectedvalue
        .split(';')
        .filter(option => option !== '')
        .map(option => option.trim());
      options.forEach(o => (o.isChecked = selectedOptions.includes(o.value)));
    }
    return options;
  }
}
