import { ComponentPortal, Portal, TemplatePortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DefaultHeaderComponent } from '../components/header/default-header/default-header.component';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  private defaultHeaderPortal = new ComponentPortal(DefaultHeaderComponent);
  private headerPortalSubject = new BehaviorSubject<Portal<any>>(this.defaultHeaderPortal);
  public headerPortal$ = this.headerPortalSubject.asObservable();

  setHeaderPortal(portal: TemplatePortal) {
    this.headerPortalSubject.next(portal);
  }

  clearHeaderPortal() {
    this.headerPortalSubject.next(this.defaultHeaderPortal);
  }
}
