// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
  It applies to page with side bar.
  Example: systemParams -> systemParams.component.scss
*/
/*
  It applies to the title (header) of page content body.
*/
/*
  It describes common behavior on mouse hover.
*/
/*
  It describes light shadow for any page block.
*/
/*
  It applies to entity block like project, facility which has own title, edit button and body.
*/
/*
  It applies together with entity-card mixin, which has been described above
*/
/*
  It applies together with 'entity-card' mixin, which has been described above.
*/
/*
  It applies together with 'entity-card' mixin, which has been described above.
*/
:host {
  display: inline-block;
  font-weight: normal;
  font-size: 12px;
  font-family: GothamNarrowBook, sans-serif;
  padding: 2px 4px;
  white-space: nowrap;
}

fa-icon {
  margin-right: 5px;
}

:host.new fa-icon,
:host.not-started fa-icon {
  color: #00708c;
}

:host.in-progress fa-icon {
  color: #009dd9;
}

:host.completed fa-icon {
  color: #2fd135;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
