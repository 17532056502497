// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
  It applies to page with side bar.
  Example: systemParams -> systemParams.component.scss
*/
/*
  It applies to the title (header) of page content body.
*/
/*
  It describes common behavior on mouse hover.
*/
/*
  It describes light shadow for any page block.
*/
/*
  It applies to entity block like project, facility which has own title, edit button and body.
*/
/*
  It applies together with entity-card mixin, which has been described above
*/
/*
  It applies together with 'entity-card' mixin, which has been described above.
*/
/*
  It applies together with 'entity-card' mixin, which has been described above.
*/
:host {
  border: 2px solid #f7f7f7;
  display: flex;
  flex-direction: column;
  font-weight: normal;
  font-size: 16px;
  font-family: GothamNarrowBook, sans-serif;
}

mat-icon {
  color: #8c8f93;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-horizontal-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.project-main-info {
  padding: 15px 15px 0px 15px;
  flex: 1;
}

.project-location-wrapper {
  padding: 10px 0px;
}

.project-card-footer {
  padding: 5px 15px;
  background-color: #f7f7f7;
}

.project-description {
  margin: 10px 0px;
}

a.project-link {
  font-weight: normal;
  font-size: 16px;
  font-family: GothamBold, sans-serif;
  color: #0b2d71;
  text-decoration: none;
  border: 0px;
}
a.project-link:hover {
  color: #0066b2;
}

.limit-text {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
}
.limit-text.title {
  -webkit-line-clamp: 1;
}
.limit-text.desc {
  -webkit-line-clamp: 3; /* number of lines to show */
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
