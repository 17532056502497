// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
  It applies to page with side bar.
  Example: systemParams -> systemParams.component.scss
*/
/*
  It applies to the title (header) of page content body.
*/
/*
  It describes common behavior on mouse hover.
*/
/*
  It describes light shadow for any page block.
*/
/*
  It applies to entity block like project, facility which has own title, edit button and body.
*/
/*
  It applies together with entity-card mixin, which has been described above
*/
/*
  It applies together with 'entity-card' mixin, which has been described above.
*/
/*
  It applies together with 'entity-card' mixin, which has been described above.
*/
:host {
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  padding: 18px 24px;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
  gap: 20px;
}

.title {
  color: #0b2d71;
  font-weight: normal;
  font-size: 16px;
  font-family: GothamBold, sans-serif;
  font-weight: normal;
  margin-bottom: 1em;
  text-align: start;
}

.button-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-around;
  margin-top: auto;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
