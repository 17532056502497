import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
import { ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { GraphService } from './graph-service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly roleNames = {
    user: 'User',
    admin: 'Admin',
  };
  loggedIn: boolean;

  constructor(private msalService: MsalService, private graphService: GraphService) {
    const allCvxAccounts = this.msalService.instance
      .getAllAccounts()
      .filter(account => account.tenantId === environment.appRegistration.tenantId);
    this.loggedIn = allCvxAccounts.length > 0;
    if (this.loggedIn) {
      const account = allCvxAccounts[0];
      this.setCurrentUser(account);
      this.setCurrentUserPhoto();
    } else {
      console.log('user not yet signed in');
    }
  }

  readonly currentUserProfileSubject = new ReplaySubject<AccountInfo>(1);
  readonly currentUserProfile$ = this.currentUserProfileSubject.asObservable();
  readonly currentUserPhotoSubject = new ReplaySubject<Blob>(1);
  readonly currentUserPhoto$ = this.currentUserPhotoSubject.asObservable();
  readonly rolesSubject = new ReplaySubject<string[]>(1);
  readonly roles$ = this.rolesSubject.asObservable();

  getCurrentUser(): AccountInfo {
    return this.msalService.instance.getActiveAccount();
  }

  setCurrentUserPhoto() {
    this.graphService
      .getCurrentUserPhoto()
      .pipe(tap(photo => this.currentUserPhotoSubject.next(photo)))
      .subscribe();
  }

  setCurrentUser(account: AccountInfo): void {
    this.msalService.instance.setActiveAccount(account);
    this.currentUserProfileSubject.next(account);
    this.rolesSubject.next(account.idTokenClaims['roles']);
  }

  isAuthorized(): boolean {
    const userInfo = this.getCurrentUser();
    return (
      !!userInfo.idTokenClaims &&
      (userInfo.idTokenClaims['roles'].includes(this.roleNames.user) || userInfo.idTokenClaims['roles'].includes(this.roleNames.admin))
    );
  }

  isAdmin(): boolean {
    const userInfo = this.getCurrentUser();
    return !!userInfo.idTokenClaims && userInfo.idTokenClaims['roles'].includes(this.roleNames.admin);
  }
}
