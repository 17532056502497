import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map, mergeMap, shareReplay, switchMap, take } from 'rxjs/operators';
import { TeamMember } from '../../models/teamMember.model';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AddEditTeamDialogComponent } from '../add-edit-team-dialog/add-edit-team-dialog.component';
import { TeamMembersService } from '../../services/team-members.service';

@Component({
  selector: 'app-project-members-list',
  templateUrl: './project-members-list.component.html',
  styleUrls: ['./project-members-list.component.scss'],
})
export class ProjectMembersListComponent implements OnInit, OnDestroy {
  constructor(private activatedRoute: ActivatedRoute, public dialog: MatDialog, private teamMembersService: TeamMembersService) {}

  private readonly subscription = new Subscription();

  private readonly updateMembersSubject = new BehaviorSubject<void>(void 0);
  private readonly updateMembers$ = this.updateMembersSubject.asObservable();

  projectId$ = this.activatedRoute.paramMap.pipe(
    map(params => params.get('projectId')),
    shareReplay(1)
  );

  readonly teamMembers$: Observable<TeamMember[]> = this.getTeamMembers();
  ngOnInit(): void {
    this.updateMembersSubject.next();
  }

  getTeamMembers(): Observable<TeamMember[]> {
    return this.updateMembers$.pipe(
      mergeMap(_ => this.projectId$),
      switchMap(projectId => this.teamMembersService.getTeamMembers(projectId))
    );
  }

  addMember() {
    this.subscription.add(
      this.projectId$.pipe(take(1)).subscribe(id => {
        this.dialog
          .open(AddEditTeamDialogComponent, {
            panelClass: 'add-edit-team-dialog',
            disableClose: true,
            maxWidth: null, // by default the library sets it to 80vw
            data: { projectId: id },
          })
          .afterClosed()
          .subscribe(_ => this.updateMembersSubject.next());
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
