// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
  It applies to page with side bar.
  Example: systemParams -> systemParams.component.scss
*/
/*
  It applies to the title (header) of page content body.
*/
/*
  It describes common behavior on mouse hover.
*/
/*
  It describes light shadow for any page block.
*/
/*
  It applies to entity block like project, facility which has own title, edit button and body.
*/
/*
  It applies together with entity-card mixin, which has been described above
*/
/*
  It applies together with 'entity-card' mixin, which has been described above.
*/
/*
  It applies together with 'entity-card' mixin, which has been described above.
*/
:host {
  display: flex;
  flex: 1;
  text-align: center;
  justify-content: center;
}

.footer-item {
  font-weight: normal;
  font-size: 16px;
  font-family: GothamNarrowBook, sans-serif;
  margin: 0 20px;
}
.footer-item a {
  text-decoration: none;
  border: none;
}
.footer-item a:link,
.footer-item a:visited {
  color: #0b2d71;
}
.footer-item a:hover {
  color: #0b2d71;
}

.footer-main {
  display: flex;
  padding: 35px 0;
  flex: 1;
  text-align: center;
  justify-content: center;
  background-color: #ededee;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
