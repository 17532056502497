import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProjectComponent } from './pages/project/project.component';
import { ProjectRoutingModule } from './project-routing.module';
import { ProjectSearchComponent } from './pages/search/project-search.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { FacilityCardComponent } from './components/facility-card/facility-card.component';
import { FacilityListComponent } from './components/facility-list/facility-list.component';
import { ProjectOverviewComponent } from './components/project-overview/project-overview.component';
import { SharedModule } from '../shared/shared.module';
import { MatDialogModule } from '@angular/material/dialog';
import { AddEditTeamComponent } from './components/add-edit-team/add-edit-team.component';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ObserversModule } from '@angular/cdk/observers';
import { MatInputModule } from '@angular/material/input';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ProjectListComponent } from './components/project-list/project-list.component';
import { AddEditTeamDialogComponent } from './components/add-edit-team-dialog/add-edit-team-dialog.component';
import { ArrayToDatasourcePipe } from './pipes/array-to-datasource.pipe';
import { ProjectFilterComponent } from './components/project-filter/project-filter.component';
import { DistinctProjectValuesPipe } from './pipes/disctinct-project-values.pipe';
import { ProjectVmToProjectPipe } from './pipes/project-vm-to-project.pipe';
import { AddEditProjectComponent } from './components/add-edit-project/add-edit-project.component';
import { AddEditProjectDialogComponent } from './components/add-edit-project-dialog/add-edit-project-dialog.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { ProjectMemberComponent } from './components/project-member/project-member.component';
import { AzureAdSearchComponent } from './components/azure-ad-search/azure-ad-search.component';
import { SearchEditMembersComponent } from './components/search-edit-members/search-edit-members.component';
import { ProjectCardComponent } from './components/project-card/project-card/project-card.component';
import { ProjectFacilitiesComponent } from './components/project-facilities/project-facilities.component';
import { ProjectMembersListComponent } from './components/project-members-list/project-members-list.component';
import { FilterMembersPipe } from './pipes/filter-members.pipe';
@NgModule({
  declarations: [
    ProjectComponent,
    ProjectSearchComponent,
    FacilityCardComponent,
    FacilityListComponent,
    ProjectOverviewComponent,
    AddEditTeamComponent,
    DashboardComponent,
    ProjectListComponent,
    ArrayToDatasourcePipe,
    ProjectFilterComponent,
    DistinctProjectValuesPipe,
    ProjectVmToProjectPipe,
    AddEditProjectComponent,
    AddEditProjectDialogComponent,
    AddEditTeamDialogComponent,
    FilterMembersPipe,
    ProjectMemberComponent,
    AzureAdSearchComponent,
    SearchEditMembersComponent,
    ProjectCardComponent,
    ProjectFacilitiesComponent,
    ProjectMembersListComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ProjectRoutingModule,
    MatDialogModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatTableModule,
    MatSortModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatDividerModule,
    SharedModule,
    MatDialogModule,
    MatListModule,
    MatSlideToggleModule,
    ObserversModule,
    OverlayModule,
  ],
  exports: [AddEditProjectComponent, AddEditTeamComponent],
})
export class ProjectModule {}
