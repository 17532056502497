// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
  It applies to page with side bar.
  Example: systemParams -> systemParams.component.scss
*/
/*
  It applies to the title (header) of page content body.
*/
/*
  It describes common behavior on mouse hover.
*/
/*
  It describes light shadow for any page block.
*/
/*
  It applies to entity block like project, facility which has own title, edit button and body.
*/
/*
  It applies together with entity-card mixin, which has been described above
*/
/*
  It applies together with 'entity-card' mixin, which has been described above.
*/
/*
  It applies together with 'entity-card' mixin, which has been described above.
*/
:host {
  background-color: white;
}
:host .title {
  font-weight: normal;
  font-size: 24px;
  font-family: GothamBold, sans-serif;
  color: #212121;
  font-weight: normal;
  margin-top: 15px;
  margin-bottom: 15px;
}
:host .body {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
  gap: 1em;
}
:host .body .card {
  border: 1px solid #c8c8c8;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 10px;
}
:host .body .card .label {
  font-weight: normal;
  font-size: 12px;
  font-family: GothamNarrowBook, sans-serif;
}
:host .body .card .value,
:host .body .card .unit {
  font-weight: normal;
  font-size: 12px;
  font-family: GothamBold, sans-serif;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
