import { Component, EventEmitter, Input, Output } from '@angular/core';
import { System } from '../../models/system.model';

@Component({
  selector: 'app-system-table',
  templateUrl: './system-table.component.html',
  styleUrls: ['./system-table.component.scss'],
})
export class SystemTableComponent {
  @Input()
  public systems: System[];

  @Output()
  public systemSelected = new EventEmitter<System[]>();
  allSystemsSelected = false;

  displayedColumns = ['selected', 'name', 'status', 'progress', 'dates', 'action'];

  onCheckboxChange(system: System) {
    this.allSystemsSelected = this.allSelected();
    this.systemSelected.emit(this.systems);
  }

  public allSelected(): boolean {
    if (this.systems == null) return false;
    let allselected = true;
    this.systems.forEach(s => (allselected = !s.selected ? false : allselected));
    return allselected;
  }

  unselectAll(): void {
    this.systems.forEach(s => (s.selected = false));
    this.allSystemsSelected = this.allSelected();
    this.systemSelected.emit(this.systems);
  }

  selectAll(): void {
    this.systems.forEach(s => (s.selected = true));
    this.allSystemsSelected = this.allSelected();
    this.systemSelected.emit(this.systems);
  }
}
