// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
  It applies to page with side bar.
  Example: systemParams -> systemParams.component.scss
*/
/*
  It applies to the title (header) of page content body.
*/
/*
  It describes common behavior on mouse hover.
*/
/*
  It describes light shadow for any page block.
*/
/*
  It applies to entity block like project, facility which has own title, edit button and body.
*/
/*
  It applies together with entity-card mixin, which has been described above
*/
/*
  It applies together with 'entity-card' mixin, which has been described above.
*/
/*
  It applies together with 'entity-card' mixin, which has been described above.
*/
:host {
  background: #fff;
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  flex: 1;
}

.arrow {
  display: flex;
  font-size: 50px;
  align-items: center;
  justify-content: center;
}

.project {
  font-weight: normal;
  font-size: 16px;
  font-family: GothamBold, sans-serif;
}

.title {
  font-weight: normal;
  font-size: 30px;
  font-family: GothamBold, sans-serif;
  color: #0b2d71;
}

.lightblue {
  color: #0066b2;
}

.grey {
  color: #6b6d6f;
}

.usr-btn {
  display: flex;
  margin-left: auto;
  color: #009dd9;
  align-items: center;
}

.facility-link {
  text-decoration: none;
  display: flex;
  align-items: center;
}

.status {
  font-weight: normal;
  font-size: 16px;
  font-family: GothamBold, sans-serif;
}

.system-info {
  display: flex;
  gap: 10px;
  flex-direction: row;
}
.system-info div:not(app-separator) {
  display: flex;
  align-items: center;
}

.progress {
  font-weight: normal;
  font-size: 12px;
  font-family: GothamNarrowBook, sans-serif;
  gap: 10px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
