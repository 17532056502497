import { BrowserAuthOptions } from '@azure/msal-browser';

const appRegistration = {
  clientId: 'fb099b4a-5b9b-45d3-9141-4f9003e2f663',
  tenantId: 'fd799da1-bfc1-4234-a91c-72b3a1cb9e26',
};

const msalConfigBrowserAuth: BrowserAuthOptions = {
  clientId: appRegistration.clientId,
  redirectUri: '/',
  postLogoutRedirectUri: '/',
  authority: `https://login.microsoftonline.com/${appRegistration.tenantId}/`,
};

export const environment = {
  msalConfig: {
    browserAuth: msalConfigBrowserAuth,
  },
  appRegistration: appRegistration,
  apiEndpoint: 'https://iconfig-api-test.azure.chevron.com',
  production: false,
  appInsights: {
    instrumentationKey: (<any>window).appInsights.config.instrumentationKey,
  },
};
