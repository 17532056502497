// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
  It applies to page with side bar.
  Example: systemParams -> systemParams.component.scss
*/
/*
  It applies to the title (header) of page content body.
*/
/*
  It describes common behavior on mouse hover.
*/
/*
  It describes light shadow for any page block.
*/
/*
  It applies to entity block like project, facility which has own title, edit button and body.
*/
/*
  It applies together with entity-card mixin, which has been described above
*/
/*
  It applies together with 'entity-card' mixin, which has been described above.
*/
/*
  It applies together with 'entity-card' mixin, which has been described above.
*/
:host {
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.0196078431);
  background-color: white;
}
:host .link {
  display: block;
  padding: 10px;
  border: 2px solid transparent;
  text-decoration: none;
}
:host .link:active,
:host .link.active-nav {
  color: #0f0f0f;
  border: 2px solid #0066af;
}
:host .name {
  font-weight: normal;
  font-size: 16px;
  font-family: GothamBold, sans-serif;
  color: #0b2d71;
}
:host .type {
  font-weight: normal;
  font-size: 12px;
  font-family: GothamNarrowBook, sans-serif;
  color: #707070;
}
:host .num_systems {
  text-align: right;
  font-weight: normal;
  font-size: 16px;
  font-family: GothamNarrowBook, sans-serif;
}
:host .num_systems .number {
  color: black;
}
:host .num_systems .label {
  font-weight: 20;
  color: #707070;
  margin-left: 5px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
