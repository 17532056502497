import { TemplatePortal } from '@angular/cdk/portal';
import { OnDestroy, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { HeaderService } from 'src/app/core/services/header.service';
import { SystemViewModel } from 'src/app/shared/models/autogenerated';
import { SystemService } from 'src/app/shared/services/system.service';

@Component({
  selector: 'app-system',
  templateUrl: './system.component.html',
  styleUrls: ['./system.component.scss'],
})
export class SystemComponent implements OnInit, OnDestroy {
  @ViewChild('headerTemplate', { static: true }) headerTemplate: TemplateRef<any>;
  @ViewChild(RouterOutlet, { static: true }) outlet;

  systems$: Observable<SystemViewModel[]>;
  private systemIds$: Observable<string[]>;

  activeSystemId: string;
  childPath: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private systemService: SystemService,
    private headerService: HeaderService,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {
    this.headerService.setHeaderPortal(new TemplatePortal(this.headerTemplate, this.viewContainerRef));
    this.systemIds$ = combineLatest([this.activatedRoute.queryParamMap, this.activatedRoute.paramMap]).pipe(
      map(([queryParams, params]) => {
        this.activeSystemId = params.get('systemId');
        return queryParams.getAll('systems');
      })
    );
    this.systems$ = this.systemService.getSystems(this.systemIds$);
  }

  ngOnDestroy(): void {
    this.headerService.clearHeaderPortal();
  }

  public onRouterItemChange() {
    this.childPath = this.outlet.activatedRoute.snapshot.parent.routeConfig.path;
  }
}
