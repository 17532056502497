// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
  It applies to page with side bar.
  Example: systemParams -> systemParams.component.scss
*/
/*
  It applies to the title (header) of page content body.
*/
/*
  It describes common behavior on mouse hover.
*/
/*
  It describes light shadow for any page block.
*/
/*
  It applies to entity block like project, facility which has own title, edit button and body.
*/
/*
  It applies together with entity-card mixin, which has been described above
*/
/*
  It applies together with 'entity-card' mixin, which has been described above.
*/
/*
  It applies together with 'entity-card' mixin, which has been described above.
*/
.display-name {
  font-weight: normal;
  font-size: 16px;
  font-family: GothamBold, sans-serif;
}

.email {
  font-weight: normal;
  font-size: 16px;
  font-family: GothamNarrowBook, sans-serif;
  color: #8c8f93;
}

.inputs {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
  flex-grow: 1;
}

.input {
  padding: 5px;
  border: 2px solid #c8c8c8;
  color: #212121;
  outline: none;
  border-radius: 0;
  transition: border-color 0.2s ease;
  width: 100%;
}
.input[disabled] {
  border-color: #c8c8c8;
  background-color: #fff;
  color: #8c8f93;
}
.input:focus {
  border-color: #0066b2;
}
.input.warning {
  border-color: #da9c36;
}
.input.ng-dirty.ng-invalid, .input.invalid {
  border-color: #e21836;
}
.input::placeholder {
  color: #6b6d6f;
}

mat-selection-list {
  background: white;
}

.cdk-overlay-wrapper {
  display: flex;
  flex: 1;
  position: relative;
}

.mat-button {
  font-weight: normal;
  font-size: 16px;
  font-family: GothamBold, sans-serif;
  background-color: #0066b2;
  color: white;
  border-radius: 0;
}

.mat-button:disabled {
  background-color: lightgrey;
}

.clear {
  position: absolute;
  right: 0;
  margin: 3px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
