// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
  It applies to page with side bar.
  Example: systemParams -> systemParams.component.scss
*/
/*
  It applies to the title (header) of page content body.
*/
/*
  It describes common behavior on mouse hover.
*/
/*
  It describes light shadow for any page block.
*/
/*
  It applies to entity block like project, facility which has own title, edit button and body.
*/
/*
  It applies together with entity-card mixin, which has been described above
*/
/*
  It applies together with 'entity-card' mixin, which has been described above.
*/
/*
  It applies together with 'entity-card' mixin, which has been described above.
*/
mat-dialog-actions {
  margin-top: 40px;
  flex: 1;
  gap: 10px;
}

.header-actions {
  display: flex;
}
.header-actions .iconf-btn {
  margin-left: auto;
}

h3 {
  margin: 0 auto;
  color: black;
}

.delete-btn {
  font-weight: normal;
  font-size: 16px;
  font-family: GothamNarrowBook, sans-serif;
  display: inline-block;
  border-width: 0;
  border-radius: 0;
  cursor: pointer;
  min-height: 30px;
  font-weight: bold;
  line-height: 22px;
  font-family: GothamBook, sans-serif;
  background-color: #e21836;
  color: #fff;
  min-height: 40px;
}
.delete-btn.minw250 {
  min-width: 250px;
}
.delete-btn.minw200 {
  min-width: 200px;
}
.delete-btn.minw150 {
  min-width: 150px;
}
.delete-btn.minw100 {
  min-width: 100px;
}
.delete-btn.minw50 {
  min-width: 50px;
}
.delete-btn.minh40 {
  min-height: 40px;
}
.delete-btn:focus {
  outline: none;
}
.delete-btn mat-icon {
  vertical-align: middle;
}

.cancel-btn {
  font-weight: normal;
  font-size: 16px;
  font-family: GothamNarrowBook, sans-serif;
  display: inline-block;
  border-width: 0;
  border-radius: 0;
  cursor: pointer;
  min-height: 30px;
  font-weight: bold;
  line-height: 22px;
  font-family: GothamBook, sans-serif;
  background-color: transparent;
  color: #0066b2;
  min-height: 40px;
}
.cancel-btn.minw250 {
  min-width: 250px;
}
.cancel-btn.minw200 {
  min-width: 200px;
}
.cancel-btn.minw150 {
  min-width: 150px;
}
.cancel-btn.minw100 {
  min-width: 100px;
}
.cancel-btn.minw50 {
  min-width: 50px;
}
.cancel-btn.minh40 {
  min-height: 40px;
}
.cancel-btn:focus {
  outline: none;
}
.cancel-btn mat-icon {
  vertical-align: middle;
}
.cancel-btn.active {
  color: #0b2d71;
  background-color: #f7f7f7;
}
.cancel-btn.margin-border {
  border: 2px solid #0066b2;
  margin: 5px 15px 5px 0;
}
.cancel-btn.selected {
  color: #fff;
  background-color: #0066b2;
}
.cancel-btn:disabled {
  cursor: default;
  pointer-events: none;
  background-color: transparent;
  color: #c8c8c8;
}

.iconf-btn {
  font-weight: normal;
  font-size: 16px;
  font-family: GothamNarrowBook, sans-serif;
  display: inline-block;
  border-width: 0;
  border-radius: 0;
  cursor: pointer;
  min-height: 30px;
  font-weight: bold;
  line-height: 22px;
  font-family: GothamBook, sans-serif;
  background-color: transparent;
  color: #0066b2;
  font-weight: normal;
  font-size: 16px;
  font-family: GothamNarrowBook, sans-serif;
  min-height: 40px;
}
.iconf-btn.minw250 {
  min-width: 250px;
}
.iconf-btn.minw200 {
  min-width: 200px;
}
.iconf-btn.minw150 {
  min-width: 150px;
}
.iconf-btn.minw100 {
  min-width: 100px;
}
.iconf-btn.minw50 {
  min-width: 50px;
}
.iconf-btn.minh40 {
  min-height: 40px;
}
.iconf-btn:focus {
  outline: none;
}
.iconf-btn mat-icon {
  vertical-align: middle;
}
.iconf-btn.active {
  color: #0b2d71;
  background-color: #f7f7f7;
}
.iconf-btn.margin-border {
  border: 2px solid #0066b2;
  margin: 5px 15px 5px 0;
}
.iconf-btn.selected {
  color: #fff;
  background-color: #0066b2;
}
.iconf-btn:disabled {
  cursor: default;
  pointer-events: none;
  background-color: transparent;
  color: #c8c8c8;
}

mat-dialog-content {
  text-align: center;
}

.content {
  font-weight: normal;
  font-size: 16px;
  font-family: GothamNarrowBook, sans-serif;
  margin: 0 auto;
  margin-top: 30px;
  text-align: center;
  padding: 0 20px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
