import { Component } from '@angular/core';
import { QuestionOption } from 'src/app/questionnaire/models/question-option.model';

@Component({
  selector: 'app-questionnaire-sample',
  templateUrl: './questionnaire-sample.component.html',
  styleUrls: ['./questionnaire-sample.component.scss'],
})
export class QuestionnaireSampleComponent {
  public radio: QuestionOption[] = [
    {
      title: 'value 1',
      value: 'value 1',
      isChecked: true,
    },
    {
      title: 'value 2',
      value: 'value 2',
      isChecked: false,
    },
  ];
}
