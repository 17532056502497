// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
  It applies to page with side bar.
  Example: systemParams -> systemParams.component.scss
*/
/*
  It applies to the title (header) of page content body.
*/
/*
  It describes common behavior on mouse hover.
*/
/*
  It describes light shadow for any page block.
*/
/*
  It applies to entity block like project, facility which has own title, edit button and body.
*/
/*
  It applies together with entity-card mixin, which has been described above
*/
/*
  It applies together with 'entity-card' mixin, which has been described above.
*/
/*
  It applies together with 'entity-card' mixin, which has been described above.
*/
.card {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  height: 100%;
}

.selected {
  border-width: 2px;
  border-style: solid;
}

.unselected {
  border-left-width: 2px;
  border-left-style: solid;
}

.utility {
  border-color: #00708a;
}

.process {
  border-color: #ee602d;
}

.content {
  flex: 1;
  padding: 10px;
}
.content .title {
  color: #0b2d71;
  font-weight: normal;
  font-size: 16px;
  font-family: GothamBold, sans-serif;
}
.content .description {
  font-weight: normal;
  font-size: 12px;
  font-family: GothamNarrowBook, sans-serif;
  color: #6b6d6f;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.footer {
  display: flex;
  justify-content: flex-end;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
