import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { FacilityViewModel, IConfigAPIClient, SystemViewModel } from 'src/app/shared/models/autogenerated';

@Injectable({
  providedIn: 'root',
})
export class FacilityService {
  private readonly refreshFacilitySubject = new BehaviorSubject<void>(void 0);
  private readonly refreshFacility$ = this.refreshFacilitySubject.asObservable();

  constructor(private iConfigAPIClient: IConfigAPIClient) {}
  public refreshFacility(): void {
    this.refreshFacilitySubject.next();
  }

  public getFacilityForCurrentRoute(activatedRoute: ActivatedRoute) {
    return combineLatest([activatedRoute.paramMap, this.refreshFacility$]).pipe(
      map(([params, _]) => params.get('facilityId')),
      filter(facilityId => facilityId != null),
      switchMap(facilityId => this.getFacility(facilityId))
    );
  }
  public getFacilitiesInProject(projectId: string): Observable<FacilityViewModel[]> {
    return this.refreshFacility$.pipe(switchMap(_ => this.iConfigAPIClient.getProjectFacilities(projectId)));
  }

  public getFacility(facilityId: string): Observable<FacilityViewModel> {
    return this.iConfigAPIClient.getFacility(facilityId);
  }

  public getSystemsInFacility(facilityId: string): Observable<SystemViewModel[]> {
    return this.iConfigAPIClient.getSystemsInFacility(facilityId);
  }

  public deleteFacility(facilityId: string): Observable<void> {
    return this.iConfigAPIClient.deleteFacility(facilityId);
  }
}
