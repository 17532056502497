import { Component, Input, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { ProjectViewModel } from 'src/app/shared/models/autogenerated';
import { FilterModel } from '../../../shared/models/filter.model';

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss'],
})
export class ProjectListComponent implements OnInit {
  @Input()
  public projects: ProjectViewModel[];
  @Input()
  myProjects: boolean;
  showGridview = false;
  displayedColumns = ['name', 'status', 'businessUnit', 'region', 'country', 'location'];
  filterModels: FilterModel[] = [
    { label: 'business unit', displayPropertyName: 'businessUnit', filterProperty: 'businessUnit' },
    { label: 'region', displayPropertyName: 'region', filterProperty: 'region' },
    { label: 'country', displayPropertyName: 'country', filterProperty: 'country' },
    { label: 'location', displayPropertyName: 'location', filterProperty: 'location' },
    { label: 'created by', displayPropertyName: 'createdByName', filterProperty: 'createdBy' },
  ];
  nonSelectFilters: FilterModel[] = [{ label: 'status', displayPropertyName: 'status', filterProperty: 'status' }];

  sort: Sort;

  toggleGridview(showGridview: boolean) {
    this.showGridview = showGridview;
  }

  ngOnInit(): void {
    if (this.myProjects) {
      this.filterModels.push({ label: 'role', displayPropertyName: 'roleTitle', filterProperty: 'roleTitle' });
      this.nonSelectFilters.push({ label: 'name', displayPropertyName: 'name', filterProperty: 'name' });
    }
  }
}
