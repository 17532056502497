import { Pipe, PipeTransform } from '@angular/core';
import { User } from '@microsoft/microsoft-graph-types';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { GraphService } from 'src/app/core/services/graph-service';
import { ProjectViewModel } from 'src/app/shared/models/autogenerated';
import { Project } from '../models/project.model';

@Pipe({
  name: 'ProjectVMToProject',
})
export class ProjectVmToProjectPipe implements PipeTransform {
  constructor(private graphService: GraphService) {}

  transform(projects: ProjectViewModel[], args?: any): Observable<Project[]> {
    const uniqueUsers = projects
      .map(p => p.createdBy)
      // Filter unique values
      .filter((item, i, ar) => ar.indexOf(item) === i)
      .reduce<{ [id: string]: Observable<User> }>(
        (acc, curr) => ({
          ...acc,
          [curr]: this.graphService.getUserByOid(curr),
        }),
        {}
      );

    return forkJoin(projects.map(p => this.convertCreatedByToName(p, uniqueUsers)));
  }

  convertCreatedByToName(project: ProjectViewModel, uniqueUsers: { [id: string]: Observable<User> }): Observable<Project> {
    return project.createdBy && uniqueUsers[project.createdBy]
      ? uniqueUsers[project.createdBy].pipe(map(u => ({ ...project, createdByName: u?.displayName } as Project)))
      : of(project);
  }
}
