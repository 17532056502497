// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
  It applies to page with side bar.
  Example: systemParams -> systemParams.component.scss
*/
/*
  It applies to the title (header) of page content body.
*/
/*
  It describes common behavior on mouse hover.
*/
/*
  It describes light shadow for any page block.
*/
/*
  It applies to entity block like project, facility which has own title, edit button and body.
*/
/*
  It applies together with entity-card mixin, which has been described above
*/
/*
  It applies together with 'entity-card' mixin, which has been described above.
*/
/*
  It applies together with 'entity-card' mixin, which has been described above.
*/
.ic-question-number__answer {
  position: relative;
}
.ic-question-number__answer .ic-question-number_warning,
.ic-question-number__answer .ic-question-number_error {
  font-weight: normal;
  font-size: 16px;
  font-family: GothamNarrowBook, sans-serif;
}
.ic-question-number__answer .ic-question-number_warning {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 15px;
  color: #212121;
  background-color: #f4fbfe;
}
.ic-question-number__answer .ic-question-number_warning .warning-container {
  display: flex;
}
.ic-question-number__answer .ic-question-number_warning .warning-container .warning-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ic-question-number__answer .ic-question-number_warning .warning-container .warning-msg-container {
  margin-left: 15px;
  font-weight: normal;
  font-size: 12px;
  font-family: GothamNarrowBook, sans-serif;
}
.ic-question-number__answer .ic-question-number_error {
  color: #e21836;
}

.input {
  padding: 5px;
  border: 2px solid #c8c8c8;
  color: #212121;
  outline: none;
  border-radius: 0;
  transition: border-color 0.2s ease;
  display: block;
  min-width: 250px;
  width: 80%;
}
.input[disabled] {
  border-color: #c8c8c8;
  background-color: #fff;
  color: #8c8f93;
}
.input:focus {
  border-color: #0066b2;
}
.input.warning {
  border-color: #da9c36;
}
.input.ng-dirty.ng-invalid, .input.invalid {
  border-color: #e21836;
}
.input::placeholder {
  color: #6b6d6f;
}
.input::-webkit-outer-spin-button, .input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.input[type=number] {
  -moz-appearance: textfield;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
