import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { ProjectViewModel, SystemViewModel } from 'src/app/shared/models/autogenerated';
import { ProjectService } from 'src/app/shared/services/project.service';
import { SystemService } from 'src/app/shared/services/system.service';

@Component({
  selector: 'app-system-header',
  templateUrl: './system-header.component.html',
  styleUrls: ['./system-header.component.scss'],
})
export class SystemHeaderComponent implements OnInit {
  system$: Observable<SystemViewModel>;
  constructor(private projectService: ProjectService, private activatedRoute: ActivatedRoute, private systemService: SystemService) {}

  project$: Observable<ProjectViewModel>;

  systemId$ = this.activatedRoute.paramMap.pipe(
    map(params => params.get('systemId')),
    filter(systemId => systemId != null)
  );

  facilityId$ = this.activatedRoute.paramMap.pipe(
    map(params => params.get('facilityId')),
    filter(facilityId => facilityId != null)
  );

  projectId$ = this.activatedRoute.paramMap.pipe(
    map(params => params.get('projectId')),
    filter(systemId => systemId != null)
  );

  ngOnInit() {
    this.project$ = this.projectId$.pipe(mergeMap(projectId => this.projectService.getProject(projectId)));
    this.system$ = this.systemService.getSystem(this.systemId$);
  }
}
