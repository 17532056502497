import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';

import { LoadingStatus, startLoading } from '../utils/loading';

/** Pipe to change an Observable into Observable with LoadingStatus which is supported by AppLoading directive.
 * Should be used when you want to show AppLoading directive and the observable will not be changed over time (e.g. short-lived observable)
 * Example:
 * TS: const obs$ = of(true).pipe(delay(1000));
 * HTML: <app-component *appLoading="obs$ | withLoading | async as obs" [data]="obs"></app-component>
 */
@Pipe({
  name: 'withLoading',
})
export class WithLoadingPipe implements PipeTransform {
  transform<T>(val: Observable<T>): Observable<LoadingStatus<T>> {
    return startLoading(val);
  }
}
