import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { FileResponse, IConfigAPIClient } from '../models/autogenerated';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(private iConfigAPIClient: IConfigAPIClient) {}

  openPfd(pfdName: string): Observable<void> {
    return this.iConfigAPIClient.downloadPfd(pfdName).pipe(
      tap(response => {
        const file = response.data;
        const fileUrl = URL.createObjectURL(file);
        window.open(fileUrl);
      }),
      map(() => {
        return;
      })
    );
  }

  private postDownload(response: FileResponse) {
    const file = response.data;
    const fileUrl = URL.createObjectURL(file);
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', fileUrl);
    link.setAttribute('download', response.fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  downloadPfd(pfdName: string): Observable<void> {
    return this.iConfigAPIClient.downloadPfd(pfdName).pipe(
      tap(response => {
        this.postDownload(response);
      }),
      map(() => {
        return;
      })
    );
  }

  downloadNarrative(fileName: string): Observable<void> {
    return this.iConfigAPIClient.downloadNarrative(fileName).pipe(
      tap(response => {
        this.postDownload(response);
      }),
      map(() => {
        return;
      })
    );
  }

  downloadAllPfds(systemId: string): Observable<void> {
    return this.iConfigAPIClient.getAllRecommendedPfds(systemId).pipe(
      tap(response => {
        this.postDownload(response);
      }),
      map(() => {
        return;
      })
    );
  }

  downloadSummary(systemId: string): Observable<void> {
    return this.iConfigAPIClient.getRecommendationSummary(systemId).pipe(
      tap(response => {
        this.postDownload(response);
      }),
      map(() => {
        return;
      })
    );
  }
}
