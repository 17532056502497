import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NEW_PROJECT_DIALOG_LINK } from 'src/app/new-project-flow/new-project-flow.constants';
import { ProjectService } from 'src/app/shared/services/project.service';

@Component({
  selector: 'app-project-search',
  templateUrl: './project-search.component.html',
  styleUrls: ['./project-search.component.scss'],
})
export class ProjectSearchComponent implements OnInit {
  constructor(private projectService: ProjectService, private router: Router) {}

  newProjectDialogLink = NEW_PROJECT_DIALOG_LINK;

  public allProjects$ = this.projectService.getMyProjects();

  ngOnInit(): void {
    this.projectService.refreshProjectList();
  }

  public navigateToNewProjectForm() {
    this.router.navigate(this.newProjectDialogLink);
  }
}
