import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'StringToBoolean',
})
export class StringToBooleanPipe implements PipeTransform {
  transform(value: string, args?: any): boolean {
    return value ? value.toLowerCase() === 'true' : null;
  }
}
