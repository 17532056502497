import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TeamMember } from '../../models/teamMember.model';

@Component({
  selector: 'app-project-member',
  templateUrl: './project-member.component.html',
  styleUrls: ['./project-member.component.scss'],
})
export class ProjectMemberComponent {
  @Input() member: TeamMember;
  @Input() roleTitles: string[];
  @Input() isReadOnly: boolean;

  @Output() delete = new EventEmitter();
  @Output() updated = new EventEmitter();

  onDelete() {
    this.delete.emit(this.member);
  }
}
