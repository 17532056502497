import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { catchError, concatMap, filter, map, pairwise, startWith, switchMap, take, tap } from 'rxjs/operators';
import { IConfigAPIClient, ParameterViewModel, RecommendationViewModel } from 'src/app/shared/models/autogenerated';
import { NIL_UUID } from 'src/app/shared/constants';
import { FooterService } from 'src/app/core/services/footer.service';
import { TemplatePortal } from '@angular/cdk/portal';
import { SystemService } from 'src/app/shared/services/system.service';
import { toggleLoading } from 'src/app/shared/utils/loading';

@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss'],
})
export class QuestionnaireComponent implements OnInit, OnDestroy {
  NIL_UUID = NIL_UUID;
  constructor(
    private iconfigAPIClient: IConfigAPIClient,
    private activatedRoute: ActivatedRoute,
    private systemService: SystemService,
    private footerService: FooterService,
    private viewContainerRef: ViewContainerRef
  ) {}

  @ViewChild('footerTemplate', { static: true }) footerTemplate: TemplateRef<any>;

  private readonly subscription = new Subscription();
  private readonly parameterUpdateSubject = new Subject<ParameterViewModel>();

  private refreshRecommendationSubject = new Subject<void>();
  private refreshQuestionnaireSubject = new BehaviorSubject<void>(void 0);
  private isLoadingSubject = new BehaviorSubject<boolean>(true);
  isLoading$ = this.isLoadingSubject.asObservable();

  public recommendation$: Observable<RecommendationViewModel>;
  public systemName: string;
  systemId$: Observable<string> = this.activatedRoute.paramMap.pipe(
    map(params => params.get('systemId')),
    filter(systemId => systemId != null)
  );
  public questions$ = this.getQuestionnaire();

  ngOnInit(): void {
    this.recommendation$ = this.getRecommendation();
    this.getSystemName();
    this.subscription.add(
      this.parameterUpdateSubject
        .pipe(
          concatMap(parameter => this.iconfigAPIClient.updateParameterByCode(parameter.code, parameter)),
          tap(_ => this.refreshQuestionnaireSubject.next()),
          tap(_ => this.systemService.refreshSystems()),
          catchError(error => {
            this.isLoadingSubject.next(false);
            throw error;
          })
        )
        .subscribe()
    );
    this.footerService.setFooterPortal(new TemplatePortal(this.footerTemplate, this.viewContainerRef));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.footerService.clearFooterPortal();
  }

  getSystemName() {
    this.subscription.add(this.systemId$.subscribe(n => this.iconfigAPIClient.getSystemById(n).subscribe(s => (this.systemName = s.name))));
  }

  public updateParameter(parameter: ParameterViewModel): void {
    this.parameterUpdateSubject.next(parameter);
    this.isLoadingSubject.next(true);
  }

  public getQuestionnaire() {
    return combineLatest([this.systemId$, this.refreshQuestionnaireSubject]).pipe(
      switchMap(([systemId, _]) => {
        return this.iconfigAPIClient.getQuestionnaireBySystemId(systemId);
      }),
      tap(() => this.refreshRecommendationSubject.next()),
      toggleLoading(this.isLoadingSubject),
      catchError(error => {
        this.isLoadingSubject.next(false);
        throw error;
      })
    );
  }

  public getRecommendation(): Observable<RecommendationViewModel> {
    return combineLatest([this.refreshRecommendationSubject, this.systemId$]).pipe(
      concatMap(([_, systemId]) => this.iconfigAPIClient.getRecommendation(systemId)),
      tap(() => this.isLoadingSubject.next(false)),
      catchError(error => {
        this.isLoadingSubject.next(false);
        throw error;
      })
    );
  }
}
